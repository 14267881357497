import React, { useState, useEffect } from "react";

import yellowArrow from "../assets/svg/charterIcons/ArrowYellow.svg";
import SchoolManagment from "./SchoolManagment";

import URL from "../Settings.json";
import SchoolContactInfo from "./SchoolContactInfo";
import { ConstructionOutlined } from "@mui/icons-material";
import axios from "axios";
const _Url = URL._Url;

function SchoolCaroucelItem({ people, lang, index, ViewIndex }) {
  return (
    <div>
      {(index == ViewIndex ||
        index == ViewIndex + 1 ||
        index == ViewIndex + 2) && (
        <SchoolManagment
          SchoolRole={lang == "EN" ? "Principal" : "المدير"}
          Name={lang == "EN" ? people.principalName : people.principalName_Ar}
          Email={people.principalEmail}
          Gender={"Male"}
          key={index}
        />
      )}
    </div>
  );
}

function SchoolContactItem({ title, phone, index, ViewIndex }) {
  return (
    <div>
      {(index == ViewIndex ||
        index == ViewIndex + 1 ||
        index == ViewIndex + 2) && (
        <SchoolContactInfo
          ContactTitle={title}
          PhoneContact={phone}
          key={index}
        />
      )}
    </div>
  );
}

export default function ManagmentCarousel({ SchoolCode, lang, token }) {
  const [ViewIndex, setViewIndex] = useState(0);
  const [CarouselLength, setCarouselLength] = useState(1);
  const [PageBy, setPageBy] = useState(3);
  const [Pages, setPages] = useState(1);

  const [schoolPeople, setSchoolPeople] = useState();
  const [schoolContactPeople, setSchoolContactPeople] = useState();

  const nextIndex = () => {
    if (ViewIndex < CarouselLength - PageBy) {
      let x = ViewIndex + PageBy;
      setViewIndex(x);
    }
  };
  const prevIndex = () => {
    if (ViewIndex >= PageBy) {
      let x = ViewIndex - PageBy;
      setViewIndex(x);
    }
  };

  const clickPage = (PageIndex) => {
    let x = PageIndex * PageBy;
    setViewIndex(x);
  };

  const getPageClass = (PageIndex) => {
    if (PageIndex == Math.floor(ViewIndex / PageBy)) {
      return "pageTriangle focusedTriangle";
    } else {
      return "pageTriangle";
    }
  };

  useEffect(() => {
    let leng = 1;
    axios
      .get(_Url + `/api/GetSchoolPersonelle/${SchoolCode}`, {
        headers: { Authorization: token },
      })
      .then((response) => {
        return response.data;
      })
      .then((datax) => {
        setSchoolPeople(datax.parentPricipal);
        setSchoolContactPeople(datax.contactInfoList);
        if (datax.contactInfoList.length > 0) {
          leng = datax.contactInfoList.length + 1;
          setCarouselLength(leng);
          setPages(Math.ceil(leng / PageBy));
          setViewIndex(0);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [SchoolCode]);

  const renderCarouselData = () => {
    return (
      schoolPeople && (
        <SchoolCaroucelItem
          people={schoolPeople}
          lang={lang}
          index={0}
          ViewIndex={ViewIndex}
          key={0}
        />
      )
    );
  };
  useEffect(() => {
    returnContactInfo();
  }, [schoolContactPeople]);

  const returnContactInfo = () => {
    return (
      schoolContactPeople &&
      schoolContactPeople.map((person) => {
        return (
          <>
            <SchoolContactItem
              title={lang == "EN" ? person.title : person.title_AR}
              phone={person.phoneNumber}
              index={person.id}
              ViewIndex={ViewIndex}
            />
          </>
        );
      })
    );
  };

  return (
    <div>
      <div className="spaceBetweenRow">
        <div>
          <img
            src={yellowArrow}
            onClick={prevIndex}
            style={{ marginTop: "200px" }}
            className={
              lang == "EN" ? "MobileSmallArrow" : " MobileSmallArrow rotateIcon"
            }
          ></img>
        </div>
        <div style={{ display: "flex", flexDirection: "column", width: "90%" }}>
          {renderCarouselData()}

          {returnContactInfo()}
        </div>
        <div>
          <img
            src={yellowArrow}
            style={{ marginTop: "200px" }}
            onClick={nextIndex}
            className={
              lang == "EN"
                ? "MobileSmallArrow rotateIcon"
                : " MobileSmallArrow "
            }
          ></img>
        </div>
      </div>
      <div className="PageRow">
        {[...Array(Pages)].map((x, i) => (
          <div className={getPageClass(i)} onClick={() => clickPage(i)}></div>
        ))}
      </div>
    </div>
  );
}
