import React, { useState, useEffect } from "react";

import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";

import { useMediaQuery } from "react-responsive";
import { useTranslation, Trans } from "react-i18next";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

import yellowArrow from "../assets/svg/charterIcons/ArrowYellow.svg";

import NoDataToDisplay from "../components/NoDataToDisplay";
import LoadingAnimation from "../components/LoadingAnimation";
import PdfAsImage from "../components/PdfAsImage";
import ResourceImage from "./components/ResourceImage";
import URL from "../Settings.json";
import axios from "axios";
const _Url = URL._Url;

function ResourceCarsoulItem({ lang, resource, index, ViewIndex }) {
  return (
    <div style={{ padding: "5px" }}>
      {(index == ViewIndex ||
        index == ViewIndex + 1 ||
        index == ViewIndex + 2) && (
        <ResourceImage
          imageLink={resource.url}
          url={resource.link}
          resourceDate={resource.uplosadDate}
          resourceTitle={resource.title}
          resourceDescription={resource.description}
          resourceType={
            lang == "EN" ? resource.resourceType : resource.resourceTypeAR
          }
          lang={lang}
        />
      )}
    </div>
  );
}

function Resources({
  selectedChildId,
  schoolCode,
  gradeId,
  classId,
  setmouseOverSection,
  lang,
  token,
}) {
  const [ViewIndex, setViewIndex] = useState(0);
  const [CarouselLength, setCarouselLength] = useState(1);
  const [PageBy, setPageBy] = useState(3);
  const [Pages, setPages] = useState(1);

  const nextIndex = () => {
    if (ViewIndex < CarouselLength - PageBy) {
      let x = ViewIndex + PageBy;
      setViewIndex(x);
    }
  };
  const prevIndex = () => {
    if (ViewIndex >= PageBy) {
      let x = ViewIndex - PageBy;
      setViewIndex(x);
    }
  };

  const clickPage = (PageIndex) => {
    let x = PageIndex * PageBy;
    setViewIndex(x);
  };

  const getPageClass = (PageIndex) => {
    if (PageIndex == Math.floor(ViewIndex / PageBy)) {
      return "pageTriangle focusedTriangle";
    } else {
      return "pageTriangle";
    }
  };

  const [resourceData, setResourceData] = useState([]);

  const { t, i18n } = useTranslation();

  const [loading, setLoading] = useState(false);

  const isMobile = useMediaQuery({ query: "(max-width: 820px)" });
  useEffect(() => {
    setLoading(true);

    setResourceData([]);
    try {
      schoolCode &&
        gradeId &&
        classId &&
        axios
          .get(
            _Url + `/api/Resource/${schoolCode}/${gradeId}/${classId}/5/-1`,
            {
              headers: { Authorization: token },
            }
          )
          .then((response) => {
            return response.data;
          })
          .then((data) => {
            data && setResourceData(data);
            setLoading(false);
            setCarouselLength(data.length);
            setPages(Math.ceil(data.length / PageBy));
          })
          .catch((error) => {
            console.log(error);
          });
    } catch (error) {
      console.log(error);
    }
  }, [schoolCode, gradeId, classId]);

  const renderResourceData = () => {
    return (
      resourceData &&
      resourceData.map((resource, index) => {
        return (
          <div style={{ padding: "5px" }}>
            <ResourceImage
              imageLink={resource.url}
              url={resource.link}
              resourceDate={resource.uplosadDate}
              resourceTitle={resource.title}
              resourceDescription={resource.description}
              lang={lang}
            />
          </div>
        );
      })
    );
  };

  const renderMobileResourceData = () => {
    return (
      resourceData &&
      resourceData.map((resource, index) => {
        return (
          <div style={{ padding: "10px" }} key={index}>
            <ResourceImage
              imageLink={resource.url}
              url={resource.link}
              resourceDate={resource.uplosadDate}
              resourceTitle={resource.title}
              resourceDescription={resource.description}
            />
          </div>
        );
      })
    );
  };

  const renderCarouselData = () => {
    return (
      resourceData &&
      resourceData.map((resource, index) => {
        return (
          <div>
            <ResourceCarsoulItem
              resource={resource}
              index={index}
              ViewIndex={ViewIndex}
              lang={lang}
            />
          </div>
        );
      })
    );
  };

  return (
    <div
      id="Resources"
      onMouseEnter={() => setmouseOverSection("Resources")}
      onMouseLeave={() => setmouseOverSection("")}
    >
      <Box className="DSectionBox" style={{ marginBottom: "200px" }}>
        <span className="compTitleD">{t("Resource.Resources")}</span>

        <div>
          {loading && <LoadingAnimation />}
          <>
            {!isMobile && resourceData && resourceData.length > 0 && (
              <div className="ResourcesRow">
                <div className="spaceBetweenRow">
                  <div>
                    <img
                      src={yellowArrow}
                      onClick={prevIndex}
                      style={{ marginTop: "200px" }}
                      className={lang == "EN" ? "" : "rotateIcon"}
                    ></img>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      width: "90%",
                      justifyContent: "center",
                    }}
                  >
                    {renderCarouselData()}
                    {/* {renderResourceData()} */}
                  </div>
                  <div>
                    <img
                      src={yellowArrow}
                      style={{ marginTop: "200px" }}
                      onClick={nextIndex}
                      className={lang == "EN" ? "rotateIcon" : ""}
                    ></img>
                  </div>
                </div>
                <div className="PageRow">
                  {[...Array(Pages)].map((x, i) => (
                    <div
                      className={getPageClass(i)}
                      onClick={() => clickPage(i)}
                    ></div>
                  ))}
                </div>
              </div>
            )}

            <div className="ResourcesRow">
              {isMobile && renderMobileResourceData()}
            </div>
          </>{" "}
          {!loading && (
            <NoDataToDisplay
              lang={lang}
              noData={resourceData && resourceData.length === 0 ? true : false}
            />
          )}
        </div>
      </Box>
    </div>
  );
}

export default Resources;
