import React, { useState, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import ReactDOM from "react-dom";
import { default as ReactSelect } from "react-select";
import { components } from "react-select";

import { useTranslation, Trans } from "react-i18next";
import URLsy from "../Settings.json";
import axios from "axios";
const _Url = URLsy._Url;

const Option = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />{" "}
        <label className="arabicDontChange">{props.label}</label>
      </components.Option>
    </div>
  );
};

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <img src="https://via.placeholder.com/30x30" />
    </components.DropdownIndicator>
  );
};

export default function TermDropDown({
  setSelectedTerm,
  URL,
  showYear = true,
  idd,
  isMulti = false,
  initialState = null,
  bgColor = "#00ADBA",
  token,

  lang,
}) {
  const { t, i18n } = useTranslation();
  const [ddData, setDDData] = useState();
  const isMobile = useMediaQuery({ query: "(max-width: 820px)" });

  useEffect(() => {
    let x = [];
    // var st = {
    //   value: 0,
    //   label: "Select Term",
    // };
    // x = [...x, st];

    axios
      .get(URL, { headers: { Authorization: token } })
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        data.map((dat) => {
          if (isMulti) {
            let xx = {
              value: dat.termName,
              label: showYear ? dat.termName + " " + dat.year : dat.termName,
            };
            x = [...x, xx];
          } else {
            let xx = {
              value: dat.termId,
              label: showYear ? dat.termName + " " + dat.year : dat.termName,
            };
            x = [...x, xx];
          }
        });
      })
      .then(() => {
        setDDData(x);
      })
      .catch((error) => {
        console.log("error here?");
        console.log(error);
      });
  }, []);

  useEffect(() => {
    setOptionSelected(initialState);
  }, [initialState]);
  const [optionSelected, setOptionSelected] = useState();
  const handleChange = (selected, triggeredAction) => {
    setOptionSelected(selected);
    if (triggeredAction.action === "clear") {
      setOptionSelected(-1);
    }
  };
  const style = {
    control: (base) => ({
      ...base,
      borderRadius: "0px",
      border: isMobile ? "none " : "2px solid white",
      borderBottom: isMobile ? "1px solid white " : "",
      backgroundColor: bgColor,
      color: "white",
      width: "260px",
      // height: isMobile ? "21px" : "41px",
      // This line disable the blue border
      fontFamily: "Uniform_Static",

      // marginTop: isMobile ? "10px" : "0px",
      // font: isMobile
      //   ? "normal normal normal 10px/14px Uniform;"
      //   : "normal normal normal 19px/9px Uniform",
    }),
    singleValue: (base) => ({
      ...base,
      color: "white",
    }),
  };
  useEffect(() => {
    let cs = "";
    try {
      setSelectedTerm(optionSelected.value);
      try {
        if (isMulti) {
          optionSelected &&
            optionSelected.map((op) => {
              cs = cs + op.label + ",";
            });
          setSelectedTerm(cs);
        }
      } catch (error) {}
    } catch (error) {}
  }, [optionSelected]);

  return (
    <div
      id={idd}
      className="d-inline-block arabicDontChange darkFont"
      data-toggle="popover"
      data-trigger="focus"
      data-content=""
    >
      <ReactSelect
        options={ddData}
        isMulti={isMulti}
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        components={{
          Option,
          IndicatorSeparator: () => null,
        }}
        className="selectTry"
        onChange={handleChange}
        allowSelectAll={true}
        value={optionSelected}
        placeholder={t("SelectTerm")}
        styles={style}
        isClearable={true}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            neutral50: "white !important", // Placeholder color
          },
        })}
      />
    </div>
  );
}
