import Chart from "react-apexcharts";

import { useTranslation, Trans } from "react-i18next";
function GradientCircle({
  LabelArray,
  seriesArray,
  rate,
  lang,
  Unexcused,
  warningNumber,
  isSmallScreen,
}) {
  const { t, i18n } = useTranslation();
  const series = seriesArray;
  const options = {
    labels: LabelArray,
    chart: {
      type: "donut",
    },
    plotOptions: {
      pie: {
        startAngle: 90,
        endAngle: -270,
      },
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      //   type: "gradient",
      colors: ["#1DAF8E", "#fcbf03"],
    },
    tooltip: {
      enabled: false,
    },
    legend: {
      show: false,
      fontSize: "19px",
      fontFamily: "Uniform",
      //   fontWeight: 400,
      markers: {
        width: 20,
        height: 20,
        fillColors: ["#1DAF8E", "#fcbf03"],
      },
      //   formatter: function (val, opts) {
      //     return val + " - " + opts.w.globals.series[opts.seriesIndex];
      //   },
    },

    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };

  return (
    <div
      style={{
        width: "250px",
        marginTop: "20px",
        borderRight: lang == "EN" && !isSmallScreen ? "1px dashed grey" : "",
        borderLeft: lang == "AR" && !isSmallScreen ? "1px dashed grey" : "",

        borderBottom: isSmallScreen ? "1px dashed grey" : "",
        paddingTop: "20px",
        paddingBottom: isSmallScreen ? "20px" : "",
      }}
    >
      <Chart
        options={options}
        type="donut"
        series={series}
        height="190px"
        width="230px"
      />
      <div className="rateRow">
        <div className="totalAbs">{t("StudentInfo.AttendanceRate")} </div>
        <div className="totalAbs" style={{ width: "10px" }}>
          :
        </div>
        <div className="percentage"> {Number(rate).toFixed(0)}%</div>
      </div>
      {/* <div className="WarningText">
        {Unexcused < 5 && (
          <div>
            {" "}
            {t("StudentInfo.Notice")} {Unexcused}
          </div>
        )}
        {Unexcused >= 5 && Unexcused <= 8 && (
          <>
            {" "}
            {t("StudentInfo.Warnings")}
            {Unexcused - 4}
          </>
        )}
        {Unexcused == 9 && <>{t("StudentInfo.FinalWarning")}</>}
        {Unexcused == 10 && <> {t("StudentInfo.AdekReferral")}</>}
        {Unexcused > 11 && <>{t("StudentInfo.WithdrawlNotice")}</>}
      </div> */}
    </div>
  );
}

export default GradientCircle;
