import MobileTopBar from "../navigation/Mobile/MobileTopBar";
import React, { useState, useEffect } from "react";

import MobileDashboard from "../Dashboard/MobileDashboard";
import SchoolProfile from "../SchoolProfile/SchoolProfile";
import Attendance from "../Attendance/Attendance";
import Resources from "../Resources/Resources";
import MediaGallary from "../MediaGallary/MediaGallary";
import Sidebar from "../navigation/Sidebar";

function MobileView({
  loggedInParent,
  setLoggedInParent,
  loggedIn,
  setloggedIn,
  viewState,
  setViewState,
  selectedChild_UserName,
  setSelectedChild_UserName,
  selectedChild_Id,
  setSelectedChild_Id,
  selectedhild_Grade,
  setSelectedChild_Grade,
  studentInfo,
  setStudentInfo,
  showSideBar,
  setShowSideBar,
  lang,
  setLang,
  logOut,
}) {
  const [dashboardactiveView, setdashboardactiveView] = useState(0);
  const getSideBarClass = () => {
    if (showSideBar) {
      return "";
    } else return "HideClass";
  };

  const getCenterScreen = () => {
    return lang == "EN" ? "centerScreenO" : "ARcenterScreenO";
  };
  return (
    <>
      <div className={getSideBarClass()}>
        <Sidebar
          viewState={viewState}
          setViewState={setViewState}
          loggedInParent={loggedInParent}
          loggedIn={loggedIn}
          setloggedIn={setloggedIn}
          selectedChild_Id={selectedChild_Id}
          setSelectedChild_Id={setSelectedChild_Id}
          selectedChild_UserName={selectedChild_UserName}
          setSelectedChild_UserName={setSelectedChild_UserName}
          studentInfo={studentInfo}
          setStudentInfo={setStudentInfo}
          showSideBar={showSideBar}
          setShowSideBar={setShowSideBar}
          lang={lang}
          setLang={setLang}
          logOut={logOut}
        />
      </div>
      {!showSideBar && (
        <>
          <MobileTopBar
            activeView={viewState}
            setActiveView={setViewState}
            loggedInParent={loggedInParent}
            showSideBar={showSideBar}
            setShowSideBar={setShowSideBar}
            dashboardactiveView={dashboardactiveView}
            setdashboardactiveView={setdashboardactiveView}
            lang={lang}
          />
          {viewState === 0 && (
            <MobileDashboard
              loggedInParent={loggedInParent}
              selectedChild={selectedChild_Id}
              selectedChild_UserID={selectedChild_UserName}
              studentInfo={studentInfo}
              setStudentInfo={setStudentInfo}
              schoolCode={studentInfo && studentInfo.schoolCode}
              gradeId={selectedhild_Grade}
              classId={studentInfo && studentInfo.classId}
              dashboardactiveView={dashboardactiveView}
              setdashboardactiveView={setdashboardactiveView}
              lang={lang}
              centerScreen={getCenterScreen()}
            />
          )}
          {viewState === 1 && (
            <SchoolProfile
              selectedChildId={selectedChild_Id}
              loggedInParent={loggedInParent}
              studentInfo={studentInfo}
              lang={lang}
              centerScreen={getCenterScreen()}
            />
          )}
          {viewState === 2 && (
            <Attendance
              ChildUserName={selectedChild_UserName}
              loggedInParent={loggedInParent}
              studentInfo={studentInfo}
              lang={lang}
              centerScreen={getCenterScreen()}
            />
          )}
          {viewState === 3 && (
            <Resources
              schoolCode={studentInfo.schoolCode}
              gradeId={selectedhild_Grade}
              classId={studentInfo.classId}
              loggedInParent={loggedInParent}
              selectedChildId={selectedChild_UserName}
              studentInfo={studentInfo}
              lang={lang}
              centerScreen={getCenterScreen()}
            />
          )}
          {viewState === 4 && (
            <MediaGallary
              selectedChild_UserID={studentInfo && studentInfo.student_Id}
              loggedInParent={loggedInParent}
              studentInfo={studentInfo}
              lang={lang}
              centerScreen={getCenterScreen()}
            />
          )}
        </>
      )}
    </>
  );
}

export default MobileView;
