import { Document, Page, pdfjs } from "react-pdf";
import React, { useState } from "react";

function PdfAsImage({ url }) {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }
  return (
    <>
      <div className="">
        <Document
          file={url}
          onLoadSuccess={onDocumentLoadSuccess}
          className="pdfImage"
        >
          <Page pageNumber={pageNumber} />
        </Document>
      </div>
    </>
  );
}

export default PdfAsImage;
