import charterLogo from "../../assets/charterSchoolsTrans.png";
import DashboardHeaderMenu from "./DashboardHeaderMenu";
import MobileHeaderMenu from "./MobileHeaderMenu";
import colorFlower from "../../assets/svg/mobile/colorLogo.png";
import whiteFlower from "../../assets/svg/mobile/whiteHalfFlower.png";
import upperPic from "../../assets/svg/mobile/upperLogo.png";

function MobileTopBar({
  activeView,
  setActiveView,
  loggedInParent,
  showSideBar,
  setShowSideBar,
  dashboardactiveView,
  setdashboardactiveView,
  lang,
}) {
  return (
    <>
      <div className="MobileTopBackground mobileHeader">
        <div className="displayRow mobileTop">
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div
              onClick={() => setShowSideBar(true)}
              style={{ zIndex: "10000" }}
            >
              {" "}
              <i
                className="fa fa-bars hamburgerMenu"
                aria-hidden="true"
                style={{ color: "white", fontSize: "20px" }}
              ></i>
            </div>
            <div className="MparentName">
              {" "}
              {loggedInParent &&
                (lang == "EN"
                  ? loggedInParent.english_FirstName
                  : loggedInParent.arabic_FirstName)}{" "}
            </div>
          </div>

          <img src={charterLogo} style={{ width: "153px", height: "50px" }} />
        </div>

        {/* {activeView === 0 && (
          <div className="MobileTopBackgroundMini">
            <DashboardHeaderMenu
              dashboardactiveView={dashboardactiveView}
              setdashboardactiveView={setdashboardactiveView}
            />
          </div>
        )} */}

        {/* 
        <div className="MobileTopBackground">
          {activeView !== 0 && (
            <MobileHeaderMenu
              activeView={activeView}
              setActiveView={setActiveView}
              loggedInParent={loggedInParent}
            />
          )}
          {activeView === 0 && (
            <DashboardHeaderMenu
              dashboardactiveView={dashboardactiveView}
              setdashboardactiveView={setdashboardactiveView}
            />
          )}
        </div> */}
      </div>
    </>
  );
}

export default MobileTopBar;
