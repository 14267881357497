import React, { useState, useEffect } from "react";
import lottie from "lottie-web";
import loading from "../assets/animation/welldone.json";

export default function DoneAnimation({
  width = 200,
  height = 200,
  reff = "xx",
}) {
  useEffect(() => {
    lottie.loadAnimation({
      container: document.querySelector("#react-logo" + reff),
      animationData: loading,
      renderer: "svg", // "canvas", "html"
      loop: true, // boolean
      autoplay: true, // boolean
    });
  }, []);

  return (
    <div id={"react-logo" + reff} style={{ width: width, height: height }} />
  );
}
