import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import GradeRow from "./GradeRow";
import Button from "@mui/material/Button";

import { useTranslation, Trans } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { ToastContainer, toast } from "react-toastify";
import URL from "../Settings.json";
import GradeExplanation from "./GradeExplanation";
import TermDropDown from "../components/TermDropDown";
import NoDataToDisplay from "../components/NoDataToDisplay";
import LoadingAnimation from "../components/LoadingAnimation";
import axios from "axios";
const _Url = URL._Url;

function AcademicRecord({
  selectedChildId,
  setmouseOverSection,
  selectedChild_UserID,
  longStudentId,
  lang,
  token,
  grade,
}) {
  const { t, i18n } = useTranslation();

  const [selectedTerm, setSelectedTerm] = useState(0);
  const [gradeData, setGradeData] = useState([]);

  const [loading, setLoading] = useState(false);

  const [latestCompletedTerm, setLatestCompletedTerm] = useState(0);

  useEffect(() => {
    // axios
    //   .get(_Url + `/api/GetLatestTermId`, { headers: { Authorization: token } })
    //   .then((response) => {
    //     return response.data;
    //   })
    //   .then((datax) => {
    //     setLatestCompletedTerm(datax);
    //   })
    //   .catch((error) => {
    //     console.log("error here?");
    //     console.log(error);
    //   });
  }, []);

  const downloadFile = (fileName) => {
    fetch(fileName, {
      method: "GET",
    })
      .then((response) => {
        response.arrayBuffer().then(function (buffer) {
          const url = window.URL.createObjectURL(new Blob([buffer]));
          const link = document.createElement("a");
          link.href = fileName;
          link.target = "_blank";
          link.setAttribute("download", "ReportCard.pdf"); //or any other extension
          document.body.appendChild(link);
          link.click();
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const downloadReportCard = () => {
    if (!selectedTerm) {
      toast(lang == "EN" ? "Select a Term" : "اختر الفصل الدراسي");
    } else {
      // toast("No Report Card Found for Student");
      fetch(
        _Url +
          `/api/File/GetReportPath/${selectedChild_UserID.substring(
            1
          )}/${selectedTerm}/1`,
        {
          headers: { Authorization: token },
        }
      )
        .then((response) => {
          return response.json();
        })
        .then((datax) => {
          console.log(datax);
          try {
            console.log(datax[0].file_FileName.replace("https://", "//"));
            downloadFile(datax[0].file_FileName.replace("https://", "//"));
          } catch (error) {
            toast("Report Card Unavailable");
          }
        })
        .catch((error) => {
          console.log("error here?");
          toast("Report Card Unavailable");
          console.log(error);
        });
    }
  };

  const isMobile = useMediaQuery({ query: "(max-width: 820px)" });
  useEffect(() => {
    setGradeData([]);
    setLoading(true);
    longStudentId &&
      selectedChild_UserID &&
      selectedChild_UserID.length > 0 &&
      axios
        .get(
          _Url +
            `/api/Login/StudentScoreCard/${selectedChild_UserID.substring(1)}`,
          { headers: { Authorization: token } }
        )
        .then((response) => {
          return response.data;
        })
        .then((datax) => {
          console.log(datax);
          setGradeData(datax);
          setLoading(false);
        })
        .catch((error) => {
          console.log("error here?");
          setGradeData([]);
          console.log(error);
        });
  }, [longStudentId]);

  const displayGradeData = () => {
    return (
      gradeData &&
      gradeData.map((sub, index) => {
        return (
          <GradeRow
            key={index}
            SubjectName={lang == "EN" ? sub.subject : sub.arSubject}
            Term1_MarkOutOf4={sub.term1_MarkOutOf4}
            Term1_MarkOutOf100={sub.term1_MarkOutOf100}
            Term2_MarkOutOf4={sub.term2_MarkOutOf4}
            Term2_MarkOutOf100={sub.term2_MarkOutOf100}
            Term3_MarkOutOf4={sub.term3_MarkOutOf4}
            Term3_MarkOutOf100={sub.term3_MarkOutOf100}
            Term4_MarkOutOf4={sub.term4_MarkOutOf4}
            Term4_MarkOutOf100={sub.term4_MarkOutOf100}
            TeacherName={sub.en_FullName}
            TeacherEmail={sub.email}
            TeacherGender={sub.gender}
            TeacherArabicName={sub.ar_FullName}
            lang={lang}
            grade={grade}
            showTerm3={showTerm3}
          />
        );
      })
    );
  };

  const showTerm3 = () => {
    console.log(grade);
    switch (grade) {
      case "K1":
      case "K2":
      case "K3":
      case "1":
      case "2":
      case "3":
      case "4":
      case "5":
      case "6":
      case "7":
      case "8":
        return true;

        break;

      default:
        return false;
        break;
    }
  };

  return (
    <div
      id="AcademicRecord"
      onMouseEnter={() => setmouseOverSection("AcademicRecord")}
      onMouseLeave={() => setmouseOverSection("")}
    >
      <Box className="DSectionBox">
        <span className="compTitleD">{t("AcademicRecord.AcademicRecord")}</span>
        <div elevation={3} className="studentInfoBox ">
          <div className="spaceBetweenRow MobileOnlyColumn ColorHeader">
            <div className="SecTitle gradePerSubjectMobile">
              {" "}
              {t("AcademicRecord.GradesPerSubject")}
            </div>
            <div className="spaceBetweenRow  MobileOnlyColumn">
              <Button
                id="DownloadReportCard"
                className="sButton w150"
                onClick={downloadReportCard}
              >
                <div className="spaceBetweenRow" style={{ width: "100%" }}>
                  <div className="sButtonText">
                    {" "}
                    {t("AcademicRecord.DownloadReportCard")}
                  </div>
                  <i
                    className="fa fa-download sButtonIcon"
                    aria-hidden="true"
                  ></i>
                </div>
              </Button>
              <TermDropDown
                idd="TermSelectDD_AcademicRecord"
                setSelectedTerm={setSelectedTerm}
                URL={_Url + `/api/GetTerms`}
                initialState={-1}
                lang={lang}
                token={token}
              />
            </div>
          </div>
          <div className="AcademicPaperRecord">
            <div className="mobileColumn" style={{ position: "relative" }}>
              {gradeData.length > 0 && !loading && (
                <div className="AcademicWrap">
                  <div
                    style={{ display: "flex", flexDirection: "row" }}
                    className="HideMobile"
                  >
                    <div className="subjectRow"></div>
                    <div className="roundedW">{t("AcademicRecord.Term1")}</div>
                    <div className="roundedW">{t("AcademicRecord.Term2")}</div>
                    {showTerm3() && (
                      <div className="roundedW">
                        {t("AcademicRecord.Term3")}
                      </div>
                    )}
                    <div className="roundedW">{t("AcademicRecord.Final")}</div>
                  </div>
                  {displayGradeData()}
                </div>
              )}
              {loading && <LoadingAnimation reff="AcademicRecordAnimation" />}
              {gradeData.length === 0 && !loading && (
                <NoDataToDisplay noData={true} lang={lang} />
              )}
            </div>
            {gradeData.length > 0 && (
              <div className="MAP_Legend">
                <div className="legendRow WithBorder">
                  <div className="roundedlegend Below"></div>
                  <div className="legendText"> {t("AcademicRecord.Below")}</div>
                  <GradeExplanation
                    Type={t("AcademicRecord.Below")}
                    Explanation={t("AcademicRecord.BelowExp")}
                    lang={lang}
                  />
                </div>

                <div className="legendRow WithBorder">
                  <div className="roundedlegend Emerging"></div>
                  <div className="legendText">
                    {" "}
                    {t("AcademicRecord.Emerging")}
                  </div>
                  <GradeExplanation
                    Type={t("AcademicRecord.Emerging")}
                    Explanation={t("AcademicRecord.EmergingExp")}
                    lang={lang}
                  />
                </div>
                <div className="legendRow WithBorder">
                  <div className="roundedlegend Approaching"></div>
                  <div className="legendText">
                    {t("AcademicRecord.Approaching")}
                  </div>
                  <GradeExplanation
                    Type={t("AcademicRecord.Approaching")}
                    Explanation={t("AcademicRecord.ApproachingExp")}
                    lang={lang}
                  />
                </div>
                <div className="legendRow WithBorder">
                  <div className="roundedlegend Meets"></div>
                  <div className="legendText"> {t("AcademicRecord.Meets")}</div>
                  <GradeExplanation
                    Type={t("AcademicRecord.Meets")}
                    Explanation={t("AcademicRecord.MeetsExp")}
                    lang={lang}
                  />
                </div>
                <div className="legendRow WithBorder">
                  <div className="roundedlegend Exceeds"></div>
                  <div className="legendText">
                    {" "}
                    {t("AcademicRecord.Exeeds")}
                  </div>
                  <GradeExplanation
                    Type={t("AcademicRecord.Exeeds")}
                    Explanation={t("AcademicRecord.ExeedExp")}
                    lang={lang}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </Box>
    </div>
  );
}

export default AcademicRecord;
