import React, { useState, useEffect } from "react";

import Header from "../components/Header";
import StickyMenu from "../navigation/StickyMenu";
import Resources from "./Resources";
import AcademicRecord from "./AcademicRecord";
import AttendanceBox from "./components/AttendanceBox";
import AttendanceRate from "./components/AttendanceRate";
import DeviceInfo from "./components/DeviceInfo";
import StudentInfo from "./components/StudentInfo";
import MapTest from "./MapTest";
import { useTranslation, Trans } from "react-i18next";

function MobileDashboard({
  loggedInParent,
  selectedChild,
  selectedChild_UserID,
  studentInfo,
  setStudentInfo,
  schoolCode,
  gradeId,
  classId,
  dashboardactiveView,
  setdashboardactiveView,
  lang,
}) {
  const { t, i18n } = useTranslation();
  return (
    <>
      {/* <StickyMenu activeView={activeView} setActiveView={setActiveView} /> */}
      <Header
        isDashbord={true}
        studentInfo={studentInfo}
        loggedInParent={loggedInParent}
        message={t("HeaderMsg.Dashboard")}
        lang={lang}
      />
      <div className="MobileCenterScreen">
        <div id="StudentInformation">
          <div className="compTitleD">
            {t("StudentInformation.GeneralInfo")}
          </div>
          <StudentInfo studentInfo={studentInfo} lang={lang} />
          <DeviceInfo selectedChild_UserID={selectedChild_UserID} lang={lang} />

          <AttendanceBox studentInfo={studentInfo} lang={lang} />
        </div>
        <AcademicRecord
          selectedChildId={selectedChild}
          selectedChild_UserID={selectedChild_UserID}
          longStudentId={studentInfo && studentInfo.st_Id}
          lang={lang}
        />
        <MapTest
          selectedChild_UserID={studentInfo && studentInfo.student_Id}
          lang={lang}
        />
        <Resources
          selectedChildId={selectedChild}
          schoolCode={schoolCode}
          gradeId={gradeId}
          classId={classId}
          lang={lang}
        />
      </div>
    </>
  );
}

export default MobileDashboard;
