import React, { useState, useEffect } from "react";

import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Header from "../components/Header";
import { useTranslation, Trans } from "react-i18next";
import Button from "@mui/material/Button";
import URL from "../Settings.json";
import NoDataToDisplay from "../components/NoDataToDisplay";
import MediaModel from "./MediaModel";
import DateRangeSelect from "../components/DateRangeSelect";
import axios from "axios";
const _Url = URL._Url;

function MediaGallary({
  selectedChild_UserID,
  loggedInParent,
  studentInfo,
  lang,
  minimized,
  centerScreen,
  token,
}) {
  const [mediaSrc, setMediaSrc] = useState([]);

  const { t, i18n } = useTranslation();

  const [dateFrom, setDateFrom] = useState("2022-01-01");
  const [dateTo, setDateTo] = useState("2022-12-31");

  useEffect(() => {
    setMediaSrc([]);
    // console.log(
    //   _Url + `/api/PPMediagallery/${selectedChild_UserID}/${dateFrom}/${dateTo}`
    // );
    axios
      .get(
        _Url +
          `/api/PPMediagallery/${selectedChild_UserID}/${dateFrom}/${dateTo}`,
        { headers: { Authorization: token } }
      )
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        setMediaSrc(data);
      })
      .catch((error) => {
        console.log("error here?");
        console.log(error);
      });
  }, [selectedChild_UserID, dateFrom, dateTo]);

  const renderImgs = () => {
    return (
      mediaSrc &&
      mediaSrc.map((media, index) => {
        return (
          <>
            <div style={{ padding: "10px" }} key={index}>
              <MediaModel
                imgSrc={media.url}
                imgTitle={media.title}
                token={token}
              />
            </div>
          </>
        );
      })
    );
  };

  return (
    <>
      <Header
        message={t("HeaderMsg.MediaGallery")}
        studentInfo={studentInfo}
        loggedInParent={loggedInParent}
        lang={lang}
        minimized={minimized}
      />
      <div className={centerScreen}>
        <Box>
          <div
            elevation={3}
            style={{ marginTop: "10px" }}
            className="AttendancePaper studentInfoBox"
          >
            <div className="spaceBetweenRow ColorHeader MobileOnlyColumn">
              <div className="compTitle"> {t("MediaGallery.Gallery")}</div>
              <DateRangeSelect
                setDate1={setDateFrom}
                setDate2={setDateTo}
                lang={lang}
              />
            </div>

            {/* <span className="mediaHeader">Recent Highlights</span> */}
            <br />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                padding: "30px",
              }}
            >
              {renderImgs()}
            </div>

            <NoDataToDisplay
              noData={mediaSrc && mediaSrc.length === 0 ? true : false}
              lang={lang}
            />
          </div>
        </Box>
      </div>
    </>
  );
}

export default MediaGallary;
