import React, { useState, useEffect } from "react";

import informationIcon from "../assets/svg/informationIcon.svg";
import { useTranslation, Trans } from "react-i18next";

function HoverComponent({
  Email,
  Phone,
  isShown,
  setIsShown,
  onBtnClick,
  Name,
  lang,
}) {
  const { t, i18n } = useTranslation();
  return (
    <div
    //  onClick={onBtnClick}
    >
      {/* <div
        onMouseEnter={() => setIsShown(true)}
        onMouseLeave={() => setIsShown(false)}
        className="greyOval"
        style={{
          borderRadius: "70px",
          textAlign: "center",
          padding: "45px 37px",
          color: "white",
          zIndex: "2500",
          position: "fixed",
        }}
      >

      </div> */}

      <div
        className={
          lang == "EN"
            ? "parentInformation parentEN"
            : "parentInformation parentAR"
        }
      >
        <div className="parentWrap">
          <div className="aboutTheParent">
            {t("SchoolProfile.AboutParent")}:
          </div>
          <div className="parentHoverInfo arabicDontChange">
            <div className="displayRow jLeft mtop10">
              <div
                style={{
                  marginRight: "10px",
                  fontWeight: "bold",
                }}
              >
                {t("SchoolProfile.Name")} {" : "}
              </div>
              <div className="nameHover">{Name}</div>
            </div>
            {/* <div className="displayRow jLeft mtop10">
              <div
                style={{
                  marginRight: "10px",
                  fontWeight: "bold",
                }}
              >
                {t("SchoolProfile.Email")}:
              </div>{" "}
              {Email}
            </div> */}
            <div className="displayRow jLeft mtop10">
              <div
                style={{
                  marginRight: "10px",
                  fontWeight: "bold",
                }}
              >
                {t("SchoolProfile.PhoneNumber")}
                {" : "}
              </div>
              <div className="nameHover"> {Phone}</div>{" "}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HoverComponent;
