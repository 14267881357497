import pdf from "../../assets/svg/resourceType/pdf.svg";
import excel from "../../assets/svg/resourceType/excel.svg";
import word from "../../assets/svg/resourceType/word.svg";
import moment from "moment";
import "moment/locale/ar";
import React, { useState, useEffect } from "react";
import PdfAsImage from "../../components/PdfAsImage";

function ResourceImage({
  url,
  resourceDate,
  resourceTitle,
  resourceDescription,
  photo,
  imageLink,
  resourceType,
  lang,
}) {
  const [imgSrc, setImgSrc] = useState(pdf);

  const [isPdf, setIsPdf] = useState(false);
  const [isImage, setIsImage] = useState(false);

  useEffect(() => {
    setIsImage(true);
    // let ext = url.substring(url.lastIndexOf(".") + 1, url.length) || url;
    // switch (ext) {
    //   case "pdf":
    //     setImgSrc(pdf);
    //     setIsPdf(true);
    //     break;
    //   case "doc":
    //     setImgSrc(word);
    //     break;
    //   case "docx":
    //     setImgSrc(word);
    //     break;
    //   case "xls":
    //   case "xlsx":
    //     setImgSrc(excel);
    //     break;
    //   default:
    //     setIsImage(true);
    //     break;
    // }
  }, [url]);

  useEffect(() => {
    moment.locale("ar");
  }, []);
  return (
    <div className="resourceBox">
      <div className="resourceTopRow">
        {/* <img src={imgSrc} className="pdfInResource"></img> */}
        {isPdf && (
          <a href={url} target="_blank">
            {" "}
            <PdfAsImage url={url} />{" "}
          </a>
        )}
        {isImage && (
          <a href={url} target="_blank">
            <img src={imageLink} className=" DashboardResourceImage"></img>
          </a>
        )}
      </div>
      <div className="resourceInnerWrap">
        <div className="resourceDate mtop20">
          {/* {moment(resourceDate).format("MMM, DD YYYY")} */}

          {moment(resourceDate)
            .locale(lang == "EN" ? "en" : "ar")
            .format("MMM, DD YYYY")}
        </div>
        <div className="resourceResourceType mtop10 ">{resourceType}</div>
        <div className="resourceTitle mtop20 ">
          {resourceTitle.length > 30
            ? resourceTitle.substring(0, 30) + " ..."
            : resourceTitle}
        </div>
        <div className="resourceDescription mtop20">
          {resourceDescription.length > 150
            ? resourceDescription.substring(0, 150) + " ..."
            : resourceDescription}
        </div>
      </div>
    </div>
  );
}

export default ResourceImage;
