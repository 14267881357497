import React, { useRef, useState, useEffect } from "react";
import progressParent from "../assets/svg/profileBack.png";
import femaleUser from "../assets/svg/maleStudent.svg";
import maleUser from "../assets/svg/maleStudent.svg";
import Tooltip from "@mui/material/Tooltip";
import moment from "moment";

import boyUser from "../assets/svg/maleStudent.svg";
import girlUser from "../assets/svg/femaleStudent.svg";

import HoverComponent from "./HoverComponent";
import ExtraChildren from "./ExtraChildren";
import URLsy from "../Settings.json";
import axios from "axios";
const _Url = URLsy._Url;

function RadialChildSelection({
  ParentId,
  selectedChild_Id,
  setSelectedChild_Id,
  selectedChild_UserName,
  setSelectedChild_UserName,
  setChildName,
  studentInfo,
  setStudentInfo,
  ParentGender,
  loggedInParent,
  lang,
  token,
}) {
  const [childn, setChildN] = useState();
  const [circleRadius, setCircleRadius] = useState(70);
  const [activeIndex, setActiveIndex] = useState(0);
  const [selectedImage, setSelectedImage] = useState(null);
  const [imgUrl, setImgUrl] = useState(femaleUser);
  const [parenturl, setParentURL] = useState("");

  useEffect(() => {
    if (ParentGender === "Father") {
      setImgUrl(maleUser);
    } else {
      setImgUrl(femaleUser);
    }
  }, [ParentGender]);

  const childClicked = (StudendId, studentUSerName, index, childName) => {
    setSelectedChild_Id(StudendId);
    setSelectedChild_UserName(studentUSerName);
    setActiveIndex(index);
    setChildName(childName);
  };

  const getCircleRadius = (index) => {
    if (index < 5) {
      return circleRadius;
    } else {
      return 75;
    }
  };

  const getTopByIndex = (index) => {
    return (
      getCircleRadius(index) -
      getCircleRadius(index) * Math.sin(getAngleInRad(index))
    );
  };

  const getLeftByIndex = (index) => {
    return (
      getCircleRadius(index) +
      getCircleRadius(index) * Math.cos(getAngleInRad(index))
    );
  };

  useEffect(() => {}, []);

  const getAngleInRad = (index) => {
    let x = 0;
    switch (index) {
      case 0:
        x = 55 * 0.0174;
        break;
      case 1:
        x = 0 * 0.0174;
        break;
      case 2:
        x = -30 * 0.0174;
        break;
      case 3:
        x = -60 * 0.0174;
        break;
      case 4:
        x = -90 * 0.0174;
        break;
      case 5:
        x = -120 * 0.0174;
        break;
      case 6:
        x = -150 * 0.0174;
        break;
      case 7:
        x = -180 * 0.0174;
        break;
      case 8:
        x = -210 * 0.0174;
        break;
      default:
        break;
    }
    return x;
  };

  const getBtnClass = (index) => {
    if (index === activeIndex) {
      return "roundedBtnX activeRounded arabicDontChange";
    } else {
      return "roundedBtnX inactiveRounded arabicDontChange";
    }
  };

  const getBtnText = (index) => {
    switch (index) {
      case 1:
        return "st";
        break;
      case 2:
        return "nd";
        break;
      case 3:
        return "rd";
        break;

      default:
        return "th";
        break;
    }
  };
  const [userImage, setUserImage] = useState(boyUser);

  const [actualPicURL, setActualPicURL] = useState();

  useEffect(() => {
    studentInfo && console.log(studentInfo.gender);
    try {
      if (studentInfo && studentInfo.gender === "Female") {
        setActualPicURL(girlUser);
        setUserImage(girlUser);
      } else {
        setActualPicURL(boyUser);
        setUserImage(boyUser);
      }
    } catch {}

    try {
      setActualPicURL(
        `https://cslp.charterschools.ae/api/api/File/GetStudentImage/${studentInfo.student_Id}`
      );
    } catch (error) {
      if (studentInfo && studentInfo.gender === "Female") {
        setActualPicURL(girlUser);
      } else {
        setActualPicURL(boyUser);
      }
    }
  }, [studentInfo]);

  const inputFileRef = useRef(null);
  useEffect(() => {
    selectedChild_Id &&
      axios
        .get(_Url + `/api/PPMainService/v2/students/${selectedChild_Id}`, {
          headers: { Authorization: token },
        })
        .then((response) => {
          return response.data;
        })
        .then((data) => {
          setStudentInfo(data[0]);
        })
        .catch((error) => {
          console.log(error);
        });
  }, [selectedChild_Id]);

  const renderBtns = () => {
    let x = [];
    return (
      childn &&
      childn.map((kid, index) => {
        if (index < 6) {
          return (
            <Tooltip
              key={index}
              title={
                lang == "EN" ? kid.studentFirstName : kid.studentArabicFirstName
              }
              placement="right-start"
            >
              <div
                key={index}
                className={getBtnClass(index)}
                onMouseEnter={() => setIsShown()}
                style={{
                  position: "absolute",
                  top: getTopByIndex(index),
                  left: getLeftByIndex(index),
                }}
                onClick={() =>
                  childClicked(
                    kid.studentId,
                    kid.studentUserName,
                    index,
                    kid.studentFirstName
                  )
                }
              >
                {index + 1}
                <sup>{getBtnText(index + 1)}</sup>
              </div>
            </Tooltip>
          );
        }
      })
    );
  };

  useEffect(() => {
    ParentId &&
      axios
        .get(_Url + `/api/PPMainService/v2/parentwithchildren/${ParentId}`, {
          headers: { Authorization: token },
        })
        .then(function (response) {
          return response.data;
        })
        .then((data) => {
          setChildN(data);
          try {
            childClicked(
              data[0].studentId,
              data[0].studentUserName,
              0,
              data[0].studentFirstName
            );
          } catch (error) {}
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        })
        .finally(function () {
          // always executed
        });
    // ParentId &&
    // fetch(_Url + `/api/PPMainService/v2/parentwithchildren/${ParentId}`)
    //   .then((response) => {
    //     return response.json();
    //   })
    //   .then((data) => {
    //     console.log("from parents with children");
    //     console.log(data);

    //     setChildN(data);
    //     try {
    //       childClicked(
    //         data[0].studentId,
    //         data[0].studentUserName,
    //         0,
    //         data[0].studentFirstName
    //       );
    //     } catch (error) {}
    //   })
    //   .catch((error) => {
    //     console.log("con continue code use effect parents with children");
    //     console.log(error);
    //   });
    ParentId &&
      setParentURL(
        _Url +
          `/api/File/GetImage/${ParentId}?time=` +
          moment().format("ddMMyyyyhhmmsstt")
      );
  }, [ParentId]);

  useEffect(() => {
    setSelectedImage(null);
  }, []);

  const [isShown, setIsShown] = useState(false);
  const onBtnClick = () => {
    inputFileRef.current.click();
  };
  useEffect(() => {
    try {
      if (selectedImage !== null) {
        setImgUrl(URL.createObjectURL(selectedImage));
      }
    } catch (error) {}
  }, [selectedImage]);

  const onPicChange = (event) => {
    if (event.target.files.length !== 0) {
      setSelectedImage(event.target.files[0]);
    }
  };

  return (
    <div className="MobilePosition">
      {/* <input
        type="file"
        name="myImage"
        ref={inputFileRef}
        style={{ width: "0px", height: "0px" }}
        onChange={(event) => {
          onPicChange(event);

          const files = event.target.files;
          const formData = new FormData();
          formData.append("FileToUpload", files[0]);
          formData.append("parentId", ParentId);
          fetch(_Url + `/api/File/ParentImage`, {
            method: "POST",
            body: formData,
          })
            .then((response) => {
              console.log(response);
              response.json(response);
            })
            .catch((error) => {
              console.error(error);
            });
        }}
      /> */}
      {isShown && (
        <HoverComponent
          Email={loggedInParent.email}
          Phone={loggedInParent.mobileNo}
          Name={
            lang === "EN"
              ? loggedInParent.english_FirstName +
                " " +
                loggedInParent.english_SecondName +
                " " +
                loggedInParent.english_FamilyName
              : loggedInParent.arabic_FirstName +
                " " +
                loggedInParent.arabic_SecondName +
                " " +
                loggedInParent.arabic_FamilyName
          }
          isShown={isShown}
          setIsShown={setIsShown}
          onBtnClick={onBtnClick}
          lang={lang}
        />
      )}
      <div style={{ position: "relative" }}>
        <div
          onMouseEnter={() => setIsShown(true)}
          onMouseLeave={() => setIsShown(false)}
        >
          {!selectedImage && (
            <img
              src={actualPicURL}
              onError={(e) => (e.target.src = userImage)}
              style={{
                borderRadius: "70%",
                width: "140px",
                height: "140px",
                position: "absolute",
                zIndex: "2000",
                top: "20px",
                left: "5px",
                // border: "5px solid #020a58",
                backgroundColor: "white",
              }}
            />
          )}

          {/* {selectedImage && (
            <>
              <div onBtnClick={onBtnClick}>
                <img
                  alt="not fount"
                  src={URL.createObjectURL(selectedImage)}
                  style={{
                    borderRadius: "70%",
                    width: "137px",
                    height: "137px",
                    position: "absolute",
                    zIndex: "2000",
                    top: "20px",
                  }}
                />
              </div>
            </>
          )} */}
        </div>
        <div
          id="radialPosition"
          style={{
            border: "",
            width: "170px",
            height: "180px",
            position: "absolute",
            top: "0px",
            left: "-5px",
          }}
        ></div>
        <img
          id="userImage"
          src={progressParent}
          style={{
            top: "-46px",
            // width: "232px",
            // height: "265px",
            position: "absolute",
            left: "-57px",
          }}
        />
        {renderBtns()}
        {childn && childn.length > 6 && (
          <ExtraChildren
            ChildrenArray={childn}
            leftIndex={getLeftByIndex(6)}
            topIndex={getTopByIndex(6)}
            childClicked={childClicked}
            activeIndex={activeIndex}
          />
        )}
      </div>
    </div>
  );
}

export default RadialChildSelection;
