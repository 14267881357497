import React, { useState, useRef, useEffect } from "react";
import URLsy from "../Settings.json";
const _Url = URLsy._Url;

function AttendanceBtn({ data, refreshData }) {
  const [hasReason, setHasReason] = useState(false);
  const inputFileRef = useRef(null);

  useEffect(() => {
    if (
      data.users_StudentAtendanceFiles &&
      data.users_StudentAtendanceFiles.length > 0
    ) {
      setHasReason(true);
    }
  }, [data]);

  const onBtnClick = () => {
    inputFileRef.current.click();
  };

  const downloadFile = (fileName) => {
    fetch(fileName, {
      method: "GET",
      headers: {},
    })
      .then((response) => {
        response.arrayBuffer().then(function (buffer) {
          const url = window.URL.createObjectURL(new Blob([buffer]));
          const link = document.createElement("a");
          link.href = fileName;
          link.target = "_blank";
          link.setAttribute("download", "ReportCard.pdf"); //or any other extension
          document.body.appendChild(link);
          link.click();
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const downloadReason = () => {
    data.users_StudentAtendanceFiles &&
      downloadFile(
        _Url +
          `/api/Attendance/GetReasonDocument/${data.users_StudentAtendanceFiles[0].aI_Id}`
      );
  };
  return (
    <div>
      {!hasReason && (
        <div className="attendanceBtn" onClick={onBtnClick}>
          Enter Attendance Reason
        </div>
      )}
      {hasReason && (
        <div className="attendanceBtn" onClick={downloadReason}>
          Download File
        </div>
      )}
      <input
        type="file"
        name="myImage"
        ref={inputFileRef}
        style={{ width: "0px", height: "0px" }}
        onChange={(event) => {
          const files = event.target.files;
          const formData = new FormData();
          formData.append("FileToUpload", files[0]);
          formData.append("File_AttendaceId", data.att_Id);
          formData.append("File_FileName", "fileName");
          formData.append("File_Term", 1);
          fetch(_Url + `/api/Attendance/UploadAttendanceFile`, {
            method: "POST",
            body: formData,
          })
            .then((response) => {
              response.json(response);
              refreshData();
            })
            .catch((error) => {
              console.error(error);
            });
        }}
      />
    </div>
  );
}

export default AttendanceBtn;
