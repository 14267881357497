import React, { useState, useEffect } from "react";

import { useMediaQuery } from "react-responsive";
import { useTranslation, Trans } from "react-i18next";
import URL from "../../Settings.json";
import GradientCircle from "./GradientCircle";

import YellowLink from "../../components/YellowLink";
import axios from "axios";
const _Url = URL._Url;

function AttendanceBox({ studentInfo, lang, token }) {
  const [attendanceRate, setAttendanceRate] = useState(0);
  const [attendanceArray, setAttendanceArray] = useState(0);
  const { t, i18n } = useTranslation();
  const [excused, setExcused] = useState(0);
  const [Unexcused, setUnExcused] = useState(0);

  const [hrefAttendance, setHrefAttendance] = useState("");

  const isSmallScreen = useMediaQuery({ query: "(max-width: 450px)" });

  const [addendanceData, setaddendanceData] = useState([100, 0]);
  useEffect(() => {
    let eSum = 0;
    let UnSum = 0;

    addendanceData.map((att) => {
      try {
        if (att.attendanceCode === "UNEXAB") {
          UnSum = UnSum + 1;
        } else {
          eSum = eSum + 1;
        }
      } catch (error) {
        console.log(error);
      }
    });

    setExcused(eSum);
    setUnExcused(UnSum);
  }, [addendanceData]);

  useEffect(() => {
    setaddendanceData([]);
    studentInfo &&
      axios
        .get(
          _Url +
            `/api/Attendance/GetAttendaceByStudentId/${studentInfo.student_Id}/-1`,
          { headers: { Authorization: token } }
        )
        .then((response) => {
          return response.data;
        })
        .then((data) => {
          setaddendanceData(data);
        })
        .catch((error) => {});

    studentInfo &&
      axios
        .get(_Url + `/api/GetPolicyBySchool/${studentInfo.schoolCode}/0`, {
          headers: { Authorization: token },
        })
        .then((response) => {
          return response.data;
        })
        .then((data) => {
          data && setHrefAttendance(data.linkUrl);
        })
        .catch((error) => {
          console.log(error);
        });
  }, [studentInfo]);

  useEffect(() => {
    studentInfo &&
      axios
        .get(
          _Url +
            `/api/PPMainService/v2/students/attendanceratebyesisid/${studentInfo.student_Id}`,
          { headers: { Authorization: token } }
        )
        .then((response) => {
          return response.data;
        })
        .then((data) => {
          try {
            let x = data[0].attendanceRate;
            let y = 100 - x;
            let xx = [x, y];

            setAttendanceArray(xx);
            setAttendanceRate(Number(data[0].attendanceRate));
          } catch (error) {}
        })
        .catch((error) => {
          console.log(error);
        });
  }, [studentInfo, lang]);
  return (
    <div elevation={3} className="studentInfoBox MarginTop5 childInfoBox ">
      <div className="SectionTitle MobileAbsents OtherBlueBackGround">
        {" "}
        {t("StudentInfo.Absents")}
      </div>

      <div className="attedanceBox">
        <GradientCircle
          LabelArray={["Attendance", "Absent"]}
          seriesArray={[attendanceRate, 100 - attendanceRate]}
          rate={attendanceRate}
          lang={lang}
          Unexcused={Unexcused}
          warningNumber={Math.floor(Unexcused / 5)}
          isSmallScreen={isSmallScreen}
        />
        <div
          style={{
            paddingLeft: "15px",
            paddingRight: "15px",
          }}
        >
          {}
          <div className="absenceNumber arabicDontChange">
            {excused + Unexcused}
          </div>
          <div className="totalAbs"> {t("Attendance.Absence")}</div>
          <div className="displayRow">
            <div className="excusedUnexcused">
              <div className="absesnes arabicDontChange">{excused}</div>
              {t("StudentInfo.Excused")}
            </div>
            <div className="excusedUnexcused">
              <div className="absesnes arabicDontChange">{Unexcused}</div>
              {t("StudentInfo.UnExcused")}
            </div>
          </div>
          {/* <div className="absenceNumber arabicDontChange mtop10">
            {Unexcused}
          </div>
          <div className="totalAbs"> {t("Attendance.NumberOfNotices")}</div> */}
        </div>
      </div>
      <YellowLink
        Link={t("Attendance.AttendancePolicy")}
        href={hrefAttendance}
        lang={lang}
      />
    </div>
  );
}

export default AttendanceBox;
