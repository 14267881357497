import React, { useState, useEffect } from "react";

import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

function ExtraChildren({
  ChildrenArray,
  leftIndex,
  topIndex,
  childClicked,
  activeIndex,
}) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getActiveClassName = (index) => {
    if (index === activeIndex) {
      return "sButton extraChildBtn SelectedExtraChild";
    } else {
      return "sButton extraChildBtn";
    }
  };
  const renderChildrenArray = () => {
    return (
      ChildrenArray &&
      ChildrenArray.map((child, index) => {
        if (index >= 6) {
          return (
            <div
              onClick={() =>
                childClicked(
                  child.studentId,
                  child.studentUsername,
                  index,
                  child.studentFirstName
                )
              }
              className={getActiveClassName(index)}
            >
              {child.studentFirstName}
            </div>
          );
        }
      })
    );
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div>
      <div
        onClick={handleClick}
        aria-describedby={id}
        style={{
          position: "absolute",
          top: topIndex,
          left: leftIndex,
        }}
        className="roundedBtnX inactiveRounded"
      >
        <i className="fa fa-user"></i>
      </div>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <div
          style={{ display: "flex", flexDirection: "column", padding: "10px" }}
        >
          {renderChildrenArray()}
        </div>
      </Popover>
    </div>
  );
}

export default ExtraChildren;
