import React, { useState, useEffect } from "react";
import phone from "../assets/svg/charterIcons/Phone.svg";
import Email from "../assets/svg/charterIcons/Email.svg";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Header from "../components/Header";
import SchoolManagment from "./SchoolManagment";
import InfoPopUp from "../components/InfoPopUp";
import emiratiSchool from "../assets/svg/resourceType/school.svg";
import { useTranslation, Trans } from "react-i18next";

import ManagmentCarousel from "./ManagmentCarousel";
import URL from "../Settings.json";
import axios from "axios";
const _Url = URL._Url;

function SchoolProfile({
  selectedChildId,
  loggedInParent,
  studentInfo,
  lang,
  minimized,
  centerScreen,
  token,
}) {
  const [SchoolInfo, setSchoolInfo] = useState();
  const { t, i18n } = useTranslation();
  const [schoolPeople, setSchoolPeople] = useState();

  const [lat, setLat] = useState(0);
  const [long, setLong] = useState(0);
  useEffect(() => {
    axios
      .get(_Url + `/api/SchoolInfo/${studentInfo.schoolCode}`, {
        headers: { Authorization: token },
      })
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        setSchoolInfo(data);
        setLat(data.latitude);
        setLong(data.longitude);
      })
      .catch((error) => {
        console.log(error);
      });
    axios
      .get(_Url + `/api/GetSchoolPersonelle/${studentInfo.schoolCode}`, {
        headers: { Authorization: token },
      })
      .then((response) => {
        return response.data;
      })
      .then((datax) => {
        setSchoolPeople(datax);
      })
      .catch((error) => {
        console.log("error here?");
        console.log(error);
      });
  }, [studentInfo]);

  const displaySchoolData = () => {
    return (
      schoolPeople &&
      schoolPeople.map((people, index) => {
        return (
          <SchoolManagment
            SchoolRole={people.occupation}
            Name={lang == "EN" ? people.en_FullName : people.ar_FullName}
            Phone={people.mobile}
            Email={people.email}
            Gender={people.gender}
            key={index}
          />
        );
      })
    );
  };
  return (
    <div>
      <Header
        message={t("HeaderMsg.SchoolProfile")}
        studentInfo={studentInfo}
        loggedInParent={loggedInParent}
        lang={lang}
        minimized={minimized}
      />
      <div className={centerScreen}>
        <div className="DisplayD_FlexRow ">
          <div className="SchoolManagmentWrapper">
            <ManagmentCarousel
              lang={lang}
              SchoolCode={studentInfo && studentInfo.schoolCode}
              token={token}
            />
          </div>
          <div>
            <div
              elevation={3}
              style={{
                zIndex: "1000",
                marginTop: "10px",
              }}
              className="  "
            >
              <div className="SchoolProfileTitle OtherBlueBackGround">
                <div className="spaceBetweenRow SchoolProfileTitle">
                  {studentInfo && lang == "EN"
                    ? studentInfo.schoolName
                    : studentInfo.schoolArabicName}

                  {/* <div className="displayRow">
            
                    {SchoolInfo && (
                      <InfoPopUp
                        HoverText={`Phone: ${SchoolInfo.contactNo}`}
                        ImgSrc={phone}
                      />
                    )}
                    {SchoolInfo && (
                      <img
                        src={Email}
                        title="Email School"
                        className="schoolIcon"
                        onClick={() =>
                          (window.location = `mailto:${SchoolInfo.email}`)
                        }
                      ></img>
                      // <InfoPopUp
                      //   HoverText={`Email: ${SchoolInfo.email}`}
                      //   ImgSrc={Email}
                      // />
                    )}
                  </div> */}
                </div>
              </div>

              <div
                className="mapBox"
                //   top:-60px;
                // dangerouslySetInnerHTML={{
                //   __html:
                //     "<iframe  style='position:relative; top:-60px; border:none;'  src='https://www.google.com/maps/d/u/0/embed?mid=19sxNUy_eSHfXTBSsjMilbHGWQFKLBee7' width='662' height='500'></iframe>",
                // }}
                // "<div style="width: 100%"><iframe width="100%" height="600" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=Malet%20St,%20London%20WC1E%207HU,%20United%20Kingdom+(Your%20Business%20Name)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"><a href="https://www.gps.ie/sport-gps/">swimming watch</a></iframe></div>"
                dangerouslySetInnerHTML={{
                  __html:
                    "<iframe  style=' border:none'  src='//maps.google.com/maps?q=" +
                    lat +
                    "," +
                    long +
                    "&z=16&output=embed' height='540px' width='100%'></iframe>",
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SchoolProfile;
