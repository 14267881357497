import React, { useState } from "react";

function FooterBtn({
  onBtnClick,
  BtnId,

  Text,
  WhiteIcon,
  YellowIcon,
  Bottom,
  minimized,
}) {
  const [hover, setHover] = useState(false);

  const getIconSrc = () => {
    if (hover) {
      return YellowIcon;
    } else {
      return WhiteIcon;
    }
  };

  return (
    <div style={{ position: "absolute", bottom: Bottom }}>
      <div
        className="footerBtn"
        id={BtnId}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        onClick={onBtnClick}
      >
        <img src={getIconSrc()} className="SidebarIcon"></img>
        {!minimized && (
          <div className="footerSideBarText bottomText">{Text}</div>
        )}
      </div>
    </div>
  );
}

export default FooterBtn;
