import Tour from "reactour";
import { useMediaQuery } from "react-responsive";
import DoneAnimation from "../components/DoneAnimation";
import React, { useState, useEffect } from "react";

import URL from "../Settings.json";
import axios from "axios";
const _Url = URL._Url;

function TourTut({
  isTourOpen,
  setisTourOpen,
  setViewState,
  loggedInParent,
  lang,
  token,
}) {
  const [steps, setSteps] = useState([]);
  const demoId = "rtl";
  const isMobile = useMediaQuery({ query: "(max-width: 920px)" });

  const nextStep = (curr) => {
    try {
      switch (curr) {
        case 0:
          break;
        case 7:
          setViewState(1);
          break;
        case 8:
          setViewState(2);
          break;
        case 9:
          setViewState(3);
          break;
        // case 10:
        //   setViewState(4);
        //   break;
        case 13:
          setViewState(0);
          break;
        case 14:
          var link = document.getElementById("Sticky_AcademicRecord");
          link.click();
          break;
        case 18:
          var link = document.getElementById("Sticky_MapScores");
          link.click();
          break;
        case 22:
          var link = document.getElementById("Sticky_Resources");
          link.click();
          break;
        default:
          break;
      }
    } catch (error) {}
  };

  const Arabicsteps = [
    {
      //0
      selector: "#SiteTutorialBtn",
      content: ({ goTo, inDOM }) => (
        <div>
          <div className="tutTitle">مرحبا بك في بوابة أولياء الأمور</div>
          سيأخذك هذا الدليل في جولة استكشافية لتزويدك بتفاصيل عن كيفية استخدام
          البوابة.
        </div>
      ),
    },
    {
      //1
      selector: "#radialPosition",
      content: ({ goTo, inDOM }) => (
        <div>
          <div className="tutTitle">اختيار الطالب</div>
          ستظهر تلقائياً الصفحة الخاصة بابنك/ابنتك المسجل(ة) في مدارس الشراكات
          التعليمية. في حال كان لديك أكثر من ابن مسجل في مدارس الشراكات
          التعليمية، فسيتم عرض أسماع الأبناء من الأكبر سناً حتى الأصغر سناً.
        </div>
      ),
    },
    {
      //1
      selector: ".activeRounded",
      content: ({ goTo, inDOM }) => (
        <div>
          <div className="tutTitle">اختيار اسم الطّالب</div>
          لتغيير اختيار اسم الطّالب، انقر على الزّرّ الدّائريّ (الأوّل والثّاني
          والثّالث ...).
        </div>
      ),
    },
    {
      //2
      selector: "#radialPosition",
      content: ({ goTo, inDOM }) => (
        <div>
          <div className="tutTitle">في حالة أكثر من 6 أبناء</div>
          في حال كان لديك أكثر من 6 أبناء مسجلين في مدارس الشراكات التعليمية،
          ستظهر قائمة بأسمائهم جميعاً لتتمكن من الاختيار من بينهم.
        </div>
      ),
    },
    {
      //3
      selector: "#radialPosition",
      content: ({ goTo, inDOM }) => (
        <div>
          <div className="tutTitle">
            عند تمرير المؤشر/ السهم على الصورة، ستظهر رسالة منبثقة تتضمن:
          </div>

          <ol>
            <li> اسمك</li>
            <li> رقم هاتفك</li>
          </ol>
        </div>
      ),
    },
    // {
    //   //4
    //   selector: ".parentName",
    //   content: ({ goTo, inDOM }) => <div>الإسم الأوّل لوليّ الأمر</div>,
    // },
    // {
    //   //5
    //   selector: "#radialPosition",
    //   content: ({ goTo, inDOM }) => (
    //     <div>
    //       <div className="tutTitle">Upload your Personal Photo</div>
    //       Click on the profile picture to update your picture
    //     </div>
    //   ),
    // },

    {
      //7
      selector: ".main-menu",
      content: ({ goTo, inDOM }) => (
        <div>قائمة لجميع الصفحات المتعلقة بإبنك/إبنتك</div>
      ),
    },
    {
      //8
      selector: "#DashboardSideBar_Btn",
      content: ({ goTo, inDOM }) => (
        <div>
          تتضمن الصفحة الرئيسية معلومات الطالب التالية:
          <div style={{ padding: "10px 20px 0px 20px" }}>
            <ol>
              <li>بيانات الطالب</li>
              <li>سجل حضور الطالب</li>
              <li>معلومات جهاز الطالب </li>
              <li>الشهادة الدراسية</li>
              <li>نتائج اختبار MAP </li>
              <li>أحدث 5 موارد</li>
            </ol>
          </div>
        </div>
      ),
    },
    {
      //9
      selector: "#SchoolProfile_Btn",
      content: ({ goTo, inDOM }) => (
        <div>
          {" "}
          <div className="tutTitle">ملف المدرسة</div>
          في هذه الصفحة ، يمكنك الاطلاع على معلومات حول مدرسة ولدك.
          <div style={{ padding: "10px 20px 0px 20px" }}>
            <ol>
              <li>موقع المدرسة</li>
              <li>أرقام التواصل</li>
            </ol>
          </div>
        </div>
      ),
    },
    {
      //10
      selector: "#Attendance_Btn",
      content: ({ goTo, inDOM }) => (
        <div>
          <div className="tutTitle">حضور الطالب</div>
          يمكنك متابعة سجل غياب الطالب ومعرفة ما إذا كان الغياب بعذر أو بدون عذر
        </div>
      ),
    },

    {
      //11
      selector: "#Resources_Btn",
      content: ({ goTo, inDOM }) => (
        <div>
          ستجد هنا جميع الموارد التي من شأنها دعمك في تطوير وتنمية مهارات أبنائك
        </div>
      ),
    },
    // {
    //   //12
    //   selector: "#MediaGallery_Btn",
    //   content: ({ goTo, inDOM }) => <div>يتم عرض صور الطالب في المدرسة</div>,
    // },
    // {
    //   //13
    //   selector: "#StudentInformation",
    //   content: ({ goTo, inDOM }) => (
    //     <div>
    //       {" "}
    //       <div className="tutTitle">معلومات عامّة </div>
    //       عرض بيانات الطّالب ومعلومات الجهاز وبيانات الغياب..
    //     </div>
    //   ),
    // },
    // {
    //   //15
    //   selector: "#AcademicRecord",
    //   content: ({ goTo, inDOM }) => (
    //     <div>
    //       {" "}
    //       <div className="tutTitle">سجل الطّالب الأكاديميّ </div>
    //       عرض درجات ولدك ومعلومات المعلم.
    //     </div>
    //   ),
    // },
    // {
    //   //14
    //   selector: "#TermSelectDD_AcademicRecord",
    //   content: ({ goTo, inDOM }) => (
    //     <div>
    //       تحديد الفصل الدراسي الذي تود الحصول على علاماته (الفصل الأوّل، الفصل
    //       الثّاني...)
    //     </div>
    //   ),
    // },

    // {
    //   //16
    //   selector: "#DownloadReportCard",
    //   content: ({ goTo, inDOM }) => <div>تحميل شهادة الفصل الدّراسيّ</div>,
    // },
    // {
    //   //17
    //   selector: ".GradeRow",
    //   content: ({ goTo, inDOM }) => (
    //     <div>
    //       <div className="tutTitle">السّجلّ الأكاديميّ </div>
    //       يمكنك أن ترى:
    //       <div style={{ padding: "10px 20px 0px 20px" }}>
    //         <ol>
    //           <li>اسم المادّة الدّراسيّة</li>
    //           <li>الدّرجات </li>
    //           <li>معلومات المعلّم</li>
    //         </ol>
    //       </div>
    //     </div>
    //   ),
    // },
    // {
    //   //18
    //   selector: ".MAP_Legend",
    //   content: ({ goTo, inDOM }) => (
    //     <div>انقر هنا لعرض المزيد من الملاحظات حول الدّرجة</div>
    //   ),
    // },
    // {
    //   //19
    //   selector: "#MapScoresTest",
    //   content: ({ goTo, inDOM }) => (
    //     <div>
    //       <div className="tutTitle"> اختبار MAP </div>
    //       عرض رسم بيانيّ لنتائج MAP الخاصة بابنك. يمكن تحميل النّتيجة الأخيرة
    //       فقط كنسخة pdf.
    //       <br />
    //     </div>
    //   ),
    // },
    // {
    //   //20
    //   selector: "#downloadMapResultsBtn",
    //   content: ({ goTo, inDOM }) => <div>تحميل أحدث نتائج MAP</div>,
    // },
    // {
    //   //21
    //   selector: "#TermSelectDD_MapTest",
    //   content: ({ goTo, inDOM }) => (
    //     <div>حدد فصلاً دراسياً واحدًا أو أكثر لتصفية نتائج MAP</div>
    //   ),
    // },
    // {
    //   //22
    //   selector: "#Resources",
    //   content: ({ goTo, inDOM }) => (
    //     <div>
    //       عرض موارد الطالب التي تم تحميلها من قبل مدرسته .
    //       <br />
    //       يعرض هذا القسم أحدث خمسة موارد.
    //     </div>
    //   ),
    // },
    {
      //23
      selector: "",
      content: ({ goTo, inDOM }) => (
        <div>
          شكرًا لك، لقد أتممت جولتك في بوابة أولياء الأمور .
          <div style={{ padding: "0px 20px" }}>
            {" "}
            <DoneAnimation />
          </div>{" "}
          <div dir="ltr">600 587236</div>إذا كنت ما زلت بحاجة إلى مساعدة، يرجى
          الاتصال على:
        </div>
      ),
    },
    // ...
  ];

  const Englishsteps = [
    {
      //0
      selector: "#SiteTutorialBtn",
      content: ({ goTo, inDOM }) => (
        <div>
          <div className="tutTitle">Welcome To the Parent Portal!</div>
          This tutorial will guide you through the parent portal.
        </div>
      ),
    },
    {
      //1
      selector: "#radialPosition",
      content: ({ goTo, inDOM }) => (
        <div>
          <div className="tutTitle">Child Selector</div>
          Your Children's names will be sorted according to their age starting
          from the oldest to youngest.
        </div>
      ),
    },
    {
      //2
      selector: ".activeRounded",
      content: ({ goTo, inDOM }) => (
        <div>
          <div className="tutTitle">Change the selected child</div>
          To change the child you are viewing, click on the circular button
          (1st, 2nd, 3rd ...).
        </div>
      ),
    },
    {
      //3
      selector: "#radialPosition",
      content: ({ goTo, inDOM }) => (
        <div>
          <div className="tutTitle">In case of more than 6 Children</div>A
          dropdown menu will appear with a child selector.
        </div>
      ),
    },
    {
      //4
      selector: "#radialPosition",
      content: ({ goTo, inDOM }) => (
        <div>
          <div className="tutTitle">Hover on Image</div>
          You will see
          <div style={{ padding: "10px 20px" }}>
            <ol>
              <li>Your Name</li>
              <li>Phone Number</li>
            </ol>
          </div>
        </div>
      ),
    },
    // {
    //   //4
    //   selector: ".parentName",
    //   content: ({ goTo, inDOM }) => <div>PARENT FIRST NAME</div>,
    // },
    // {
    //   //5
    //   selector: "#radialPosition",
    //   content: ({ goTo, inDOM }) => (
    //     <div>
    //       <div className="tutTitle">Upload your Personal Photo</div>
    //       Click on the profile picture to update your picture
    //     </div>
    //   ),
    // },

    {
      //5
      selector: ".main-menu",
      content: ({ goTo, inDOM }) => (
        <div>Menu for all pages related to your child</div>
      ),
    },
    {
      //6
      selector: "#DashboardSideBar_Btn",
      content: ({ goTo, inDOM }) => (
        <div>
          <div className="tutTitle">Dashboard</div>
          In this page you can see:
          <div style={{ padding: "10px 20px 0px 20px" }}>
            <ol>
              <li>Child Information</li>
              <li>Attendance</li>
              <li>Device Inforamation</li>
              <li>Academic Record</li>
              <li>Map Test Results</li>
              <li>Latest 5 Resources</li>
            </ol>
          </div>
        </div>
      ),
    },
    {
      //7
      selector: "#SchoolProfile_Btn",
      content: ({ goTo, inDOM }) => (
        <div>
          {" "}
          <div className="tutTitle">School Profile</div>
          In this page you can see information about your child's school.
          <div style={{ padding: "10px 20px 0px 20px" }}>
            <ol>
              <li>School Location</li>
              <li>Contact Numbers</li>
            </ol>
          </div>
        </div>
      ),
    },
    {
      //8
      selector: "#Attendance_Btn",
      content: ({ goTo, inDOM }) => (
        <div>
          <div className="tutTitle">Child's Attendance</div>
          Monitor your child's absence. <br />
          You may see if their absence has been excused or unexcused.
        </div>
      ),
    },

    {
      //9
      selector: "#Resources_Btn",
      content: ({ goTo, inDOM }) => (
        <div>
          <div className="tutTitle">Students' Resources</div>
          In this page, you can view the resources uploaded by the school.{" "}
          <br />
          Resource contains a link
          <br /> Click on the image to view the link
        </div>
      ),
    },
    // {
    //   //10
    //   selector: "#MediaGallery_Btn",
    //   content: ({ goTo, inDOM }) => (
    //     <div>
    //       <div className="tutTitle">Gallery </div>
    //       In this page, the photos that your child has been tagged in will
    //       appear.
    //     </div>
    //   ),
    // },
    // {
    //   //13
    //   selector: "#StudentInformation",
    //   content: ({ goTo, inDOM }) => (
    //     <div>
    //       {" "}
    //       <div className="tutTitle">General Information </div>
    //       View the Student’s information, device information and absence data.
    //     </div>
    //   ),
    // },
    // {
    //   //15
    //   selector: "#AcademicRecord",
    //   content: ({ goTo, inDOM }) => (
    //     <div>
    //       {" "}
    //       <div className="tutTitle">Student’s Academic Record </div>
    //       View your child's grades and teacher information.
    //     </div>
    //   ),
    // },
    // {
    //   //14
    //   selector: "#TermSelectDD_AcademicRecord",
    //   content: ({ goTo, inDOM }) => (
    //     <div>
    //       Use this filter to specify which term's grades you want to see.
    //     </div>
    //   ),
    // },

    // {
    //   //16
    //   selector: "#DownloadReportCard",
    //   content: ({ goTo, inDOM }) => (
    //     <div>Download the completed term Report Card</div>
    //   ),
    // },
    // {
    //   //17
    //   selector: ".GradeRow",
    //   content: ({ goTo, inDOM }) => (
    //     <div>
    //       <div className="tutTitle">Academic Record </div>
    //       You can see
    //       <div style={{ padding: "10px 20px 0px 20px" }}>
    //         <ol>
    //           <li>Subject Name</li>
    //           <li>Grade </li>
    //           <li>Teacher Information</li>
    //         </ol>
    //       </div>
    //     </div>
    //   ),
    // },
    // {
    //   //18
    //   selector: ".MAP_Legend",
    //   content: ({ goTo, inDOM }) => (
    //     <div>Click here to display the mark description.</div>
    //   ),
    // },
    // {
    //   //19
    //   selector: "#MapScoresTest",
    //   content: ({ goTo, inDOM }) => (
    //     <div>
    //       <div className="tutTitle"> Child's MAP Test </div>
    //       View a graphical representation of your child's MAP results.
    //       <br />
    //       Only the latest result be downloaded as pdf.
    //     </div>
    //   ),
    // },
    // {
    //   //20
    //   selector: "#downloadMapResultsBtn",
    //   content: ({ goTo, inDOM }) => <div>Download latest MAP results</div>,
    // },
    // {
    //   //21
    //   selector: "#TermSelectDD_MapTest",
    //   content: ({ goTo, inDOM }) => (
    //     <div>Select one or more terms to filter MAP results</div>
    //   ),
    // },
    // {
    //   //22
    //   selector: "#Resources",
    //   content: ({ goTo, inDOM }) => (
    //     <div>
    //       View the child's resources uploaded by their school.
    //       <br />
    //       This section shows the latest five resources.
    //     </div>
    //   ),
    // },
    {
      //11
      selector: "",
      content: ({ goTo, inDOM }) => (
        <div>
          Thank you!
          <br />
          You have completed the Parent Portal Tour.
          <div style={{ padding: "0px 20px" }}>
            {" "}
            <DoneAnimation />
          </div>
          If you need any help please contact 600 587236
        </div>
      ),
    },
    // ...
  ];
  const Mobilesteps = [
    {
      //0
      selector: "#SiteTutorialBtn",
      content: ({ goTo, inDOM }) => (
        <div>
          <div className="tutTitle">Welcome To the Parent Portal!</div>
          This tutorial will guide you through the parent portal.
        </div>
      ),
    },
    {
      //1
      selector: "#userImage",
      content: ({ goTo, inDOM }) => (
        <div>
          <div className="tutTitle">Child Selector</div>
          Your children are organized from oldest to youngest.
        </div>
      ),
    },
    {
      //1
      selector: ".activeRounded",
      content: ({ goTo, inDOM }) => (
        <div>
          <div className="tutTitle">Change the selected child</div>
          To change the child you are viewing, click on the circular button
          (1st, 2nd, 3rd ...).
        </div>
      ),
    },
    {
      //2
      selector: "#userImage",
      content: ({ goTo, inDOM }) => (
        <div>
          <div className="tutTitle">In case of more than 6 Children</div>A
          dropdown will appear with a child selector.
        </div>
      ),
    },
    {
      //3
      selector: "#userImage",
      content: ({ goTo, inDOM }) => (
        <div>
          <div className="tutTitle">Hover on Image</div>
          You will see
          <div style={{ padding: "10px 20px" }}>
            <ol>
              <li>Your Name</li>
              <li>Email</li>
              <li>Phone Number</li>
            </ol>
          </div>
        </div>
      ),
    },
    {
      //4
      selector: ".parentName",
      content: ({ goTo, inDOM }) => <div>PARENT FIRST NAME</div>,
    },
    {
      //5
      selector: "#userImage",
      content: ({ goTo, inDOM }) => (
        <div>
          <div className="tutTitle">Upload your Personal Photo</div>
          Click on the profile picture to update your picture
        </div>
      ),
    },

    {
      //7
      selector: ".main-menu",
      content: ({ goTo, inDOM }) => (
        <div>Menu for all pages related to your child</div>
      ),
    },
    {
      //8
      selector: "#DashboardSideBar_Btn",
      content: ({ goTo, inDOM }) => (
        <div>
          <div className="tutTitle">Dashboard</div>
          In this page you can see:
          <div style={{ padding: "10px 20px 0px 20px" }}>
            <ol>
              <li>Child Information</li>
              <li>Academic Record</li>
              <li>Map Scores</li>
              <li>Latest 5 Resources</li>
            </ol>
          </div>
        </div>
      ),
    },
    {
      //9
      selector: "#SchoolProfile_Btn",
      content: ({ goTo, inDOM }) => (
        <div>
          {" "}
          <div className="tutTitle">School Profile</div>
          In this page you can see information about your child's school.
          <div style={{ padding: "10px 20px 0px 20px" }}>
            <ol>
              <li>School Location</li>
              <li>School Email, Phone</li>
              <li>Contact Numbers</li>
            </ol>
          </div>
        </div>
      ),
    },
    {
      //10
      selector: "#Attendance_Btn",
      content: ({ goTo, inDOM }) => (
        <div>
          <div className="tutTitle">Child's Attendance</div>
          View the days your child has been absent. <br />
          You may see if their absence has been excused or not.
        </div>
      ),
    },

    {
      //11
      selector: "#Resources_Btn",
      content: ({ goTo, inDOM }) => (
        <div>
          <div className="tutTitle">Child's Resources</div>
          In this page, resources uploaded by the school can be viewed. <br />
          Resource contains a link.
          <br /> Click on the image to view the link.
        </div>
      ),
    },

    {
      //23
      selector: "",
      content: ({ goTo, inDOM }) => (
        <div>
          Thank you!
          <br />
          You have completed the Parent Portal Tour.
          <div style={{ padding: "0px 20px" }}>
            {" "}
            <DoneAnimation />
          </div>
          If you need any help please contact + 600 587236
        </div>
      ),
    },
    // ...
  ];
  const closeTour = () => {
    setisTourOpen(false);

    axios
      .post(_Url + `/api/UpdateParentPortalSeen/${loggedInParent.parent_Id}`, {
        method: "POST",
        headers: { Authorization: token },
      })
      .then((response) => {
        console.log(response);
      })

      .catch((error) => {
        console.log("error here?");
        console.log(error);
      });
  };
  useEffect(() => {
    if (lang == "EN") {
      setSteps(Englishsteps);
    } else {
      setSteps(Arabicsteps);
    }
  }, [lang]);
  return (
    <Tour
      getCurrentStep={(curr) => nextStep(curr)}
      steps={steps}
      isOpen={isTourOpen}
      showNavigationNumber={false}
      showNumber={false}
      onRequestClose={() => closeTour()}
      className="tourPopUp"
      rounded={8}
      maskSpace={false}
      rtl
      // maskClassName="maskClasss"
    />
  );
}

export default TourTut;
