import React, { useState, useEffect } from "react";

import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Header from "../components/Header";
import { useMediaQuery } from "react-responsive";

import noresource from "../assets/noResources.png";
import NoDataToDisplay from "../components/NoDataToDisplay";

import { useTranslation, Trans } from "react-i18next";

import URL from "../Settings.json";
import ResourceImage from "../Dashboard/components/ResourceImage";
import axios from "axios";
import ReactSelect from "react-select";
const _Url = URL._Url;

function Resources({
  schoolCode,
  gradeId,
  classId,
  loggedInParent,
  selectedChildId,
  studentInfo,
  lang,
  minimized,
  centerScreen,
  token,
}) {
  const empty = {
    value: -1,
    label: lang == "EN" ? "Select Resource" : "اختر المورد",
  };
  const [resourceData, setResourceData] = useState();

  const [selectResourceType, setSelecedResourceType] = useState(empty);

  const [resourceTypeOptions, setResourceTypeOptions] = useState();

  useEffect(() => {
    let x = [];

    x = [...x, empty];
    axios
      .get(_Url + `/api/GetResouceTypes`, {
        headers: { Authorization: token },
      })
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        data &&
          data.map((d) => {
            let xx = {
              value: d.rE_Id,
              label: lang == "EN" ? d.rE_Type : d.rE_Type_AR,
            };

            x = [...x, xx];
          });
      })
      .then(() => {
        setResourceTypeOptions(x);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [lang]);

  const { t, i18n } = useTranslation();

  const isMobile = useMediaQuery({ query: "(max-width: 820px)" });
  const renderMobileData = () => {
    return (
      resourceData &&
      resourceData.map((resource, index) => {
        return (
          <div style={{ padding: "10px" }}>
            <ResourceImage
              resourceDate={resource.uploadDate}
              resourceTitle={resource.title}
              resourceDescription={resource.description}
              imageLink={resource.url}
              url={resource.link}
              resourceType={resource.resourceType}
              lang={lang}
            />
            {/* <div style={{ marginTop: "15px" }}>
              <span className="resourceHeader">{t("Resource.Title")}: </span>
              <span className="description">{resource.title}</span>
              <br />
              <span className="description">{t("Resource.Description")}: </span>
              <br /> <div className="description">{resource.description}</div>
            </div> */}
          </div>
        );
      })
    );
  };

  const renderResourceData = () => {
    return (
      resourceData &&
      resourceData.map((resource, index) => {
        return (
          <div style={{ padding: "10px" }} key={index}>
            <ResourceImage
              resourceDate={resource.uploadDate}
              resourceTitle={resource.title}
              resourceDescription={resource.description}
              imageLink={resource.url}
              url={resource.link}
              resourceType={
                lang == "EN" ? resource.resourceType : resource.resourceTypeAR
              }
              lang={lang}
            />
          </div>
        );
      })
    );
  };

  useEffect(() => {
    setResourceData([]);
    schoolCode &&
      gradeId &&
      classId &&
      axios
        .get(
          _Url +
            `/api/Resource/${schoolCode}/${gradeId}/${classId}/100/${selectResourceType.value}`,
          {
            headers: { Authorization: token },
          }
        )
        .then((response) => {
          return response.data;
        })
        .then((data) => {
          data && setResourceData(data);
        })
        .catch((error) => {
          console.log(error);
        });
  }, [schoolCode, gradeId, classId, selectResourceType]);
  const style = {
    control: (base) => ({
      ...base,
      borderRadius: "0px",
      border: isMobile ? "none " : "2px solid white",
      borderBottom: isMobile ? "1px solid white " : "",
      width: "300px",
      color: "white",
      width: "260px",
      zindex: 100,
      // height: isMobile ? "21px" : "41px",
      // This line disable the blue border
      fontFamily: "Uniform_Static",

      // marginTop: isMobile ? "10px" : "0px",
      // font: isMobile
      //   ? "normal normal normal 10px/14px Uniform;"
      //   : "normal normal normal 19px/9px Uniform",
    }),
    singleValue: (base) => ({
      ...base,
      color: "white",
    }),
  };

  const handleChange = (selected, triggeredAction) => {
    setSelecedResourceType(selected);
    if (triggeredAction.action === "clear") {
      setSelecedResourceType(empty);
    }
  };
  return (
    <>
      <Header
        message={t("HeaderMsg.Resources")}
        studentInfo={studentInfo}
        loggedInParent={loggedInParent}
        lang={lang}
        minimized={minimized}
      />
      <div className={centerScreen}>
        <Box>
          <div
            elevation={3}
            style={{ marginTop: "10px" }}
            className="AttendancePaper studentInfoBox "
          >
            <div className="compTitle ColorHeader">
              {t("Resource.Resources")}{" "}
            </div>
            <br />
            <div style={{ width: "300px" }}>
              <ReactSelect
                options={resourceTypeOptions}
                closeMenuOnSelect={true}
                hideSelectedOptions={false}
                // components={{
                //   Option,
                //   IndicatorSeparator: () => null,
                // }}
                onChange={handleChange}
                allowSelectAll={true}
                value={selectResourceType}
                placeholder={t("SelectTerm")}
                isClearable={true}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    neutral50: "white !important", // Placeholder color
                  },
                })}
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                justifyContent: "center",
              }}
            >
              {!isMobile && renderResourceData()}
            </div>

            {isMobile && renderMobileData()}
            <NoDataToDisplay
              noData={resourceData && resourceData.length === 0 ? true : false}
              lang={lang}
            />
          </div>
        </Box>
      </div>
    </>
  );
}

export default Resources;
