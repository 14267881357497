import React, { useState, useEffect } from "react";
import { useMediaQuery } from "react-responsive";

import Chart from "react-apexcharts";
import URL from "../Settings.json";
import ChartLegend from "./components/ChartLegend";
import axios from "axios";
const _Url = URL._Url;

function MapApexChart({
  MapTitle,
  StudentId,
  MapSubjectName,
  MathCourseName,
  setShowChart,
  selectedTerm,
  lang,
  token,
}) {
  const [labels, setLabels] = useState([]);
  const [data1, setData1] = useState([]);
  const [benchMark, setbenchMark] = useState([]);
  const [circularMarker, setcircularMarker] = useState([]);

  const [data, setData] = useState();
  const isMobile = useMediaQuery({ query: "(max-width: 820px)" });
  const isBigScreen = useMediaQuery({ query: "(min-width: 1824px)" });
  const isTablet = useMediaQuery({ query: "(min-width: 1100px)" });
  const is125 = useMediaQuery({ query: "(-webkit-device-pixel-ratio: 1.25)" });
  const [chartWidth, setChartWidth] = useState(500);
  const [chartHeight, setChartHeight] = useState(500);
  useEffect(() => {
    if (isBigScreen) {
      setChartWidth(450);
      setChartHeight(400);
    } else if (isTablet) {
      setChartWidth(400);
      setChartHeight(400);
    } else if (is125) {
      setChartWidth(450);
      setChartHeight(400);
    } else {
      setChartWidth(350);
      setChartHeight(350);
    }
  }, [isBigScreen]);

  useEffect(() => {
    let lab = [];
    let datarit = [];
    let benchmark = [];
    let circleMarker = [];
    const testUrl =
      _Url + `/api/GetMapScore/${StudentId}/${MapSubjectName}/${selectedTerm}`;

    try {
      StudentId &&
        axios
          .get(testUrl, { headers: { Authorization: token } })
          .then((response) => {
            return response.data;
          })
          .then((datax) => {
            // console.log(datax);
            datax.map((d) => {
              lab = [...lab, d.termName];
              datarit = [...datarit, d.testRITScore];
              benchmark = [...benchmark, d.benchmark];
              circleMarker = [...circleMarker, d.testRITScore - 25];
            });
          })
          .then(() => {
            if (lab && lab.length === 0) {
              console.log("empty");
              setShowChart(false);
              setLabels([]);
              setData1([]);
              setbenchMark([]);
              setcircularMarker([]);
            }
            setLabels(lab);
            setData1(datarit);
            setbenchMark(benchmark);
            setcircularMarker(circleMarker);
          })
          .catch((error) => {
            console.log(error);
          });
    } catch (error) {}
  }, [StudentId, selectedTerm]);
  const series = [
    {
      name: "MAP Score",
      type: "line",
      data: data1,
    },
    {
      name: "Benchmark",
      type: "column",
      data: benchMark,
    },
    // {
    //   name: "NWEA Score",
    //   type: "line",
    //   data: circularMarker,
    //   legend: {
    //     show: false,
    //   },
    // },
  ];
  const options = {
    chart: {
      height: chartHeight,
      width: chartWidth,
      type: "column",
      toolbar: {
        show: false,
      },
    },
    stroke: {
      width: [5],
      dashArray: [10],
    },
    markers: {
      size: [7],

      dashArray: [10],
    },
    grid: {
      padding: {
        top: 0,
        right: 35,
        bottom: 0,
        left: 0,
      },
    },
    colors: ["#fcbf03", "#020a58", "white"],
    fill: {
      type: "gradient",
    },
    plotOptions: {
      bar: {
        columnWidth: "50%",
        borderRadius: 0,
      },
    },
    dataLabels: {
      enabled: false,
    },
    labels: labels,
    legend: {
      show: false,
    },

    yaxis: {
      opposite: lang == "EN" ? false : true,
      labels: {
        show: true,

        // hideOverlappingLabels: true,
        // showDuplicates: false,
        // trim: false,
        // minHeight: undefined,
        // maxHeight: 120,
        style: {
          colors: [],
          fontSize: isBigScreen ? "15px" : "10px",
          fontFamily: "Uniform_Static",
          fontWeight: 400,
        },
      },
    },
    xaxis: {
      labels: {
        show: true,
        rotate: lang == "EN" ? -45 : 45,
        rotateAlways: true,
        // hideOverlappingLabels: true,
        // showDuplicates: false,
        // trim: false,
        // minHeight: undefined,
        // maxHeight: 120,
        style: {
          colors: [],
          fontSize: isBigScreen ? "15px" : "10px",
          fontFamily: "Uniform_Static",
          fontWeight: 400,
        },
        offsetX: 0,
        offsetY: 0,
      },
    },
  };

  return (
    <div id="MapSection">
      <div className="MapTitle">{MapTitle}</div>

      <div className="MapChartRow">
        {isMobile && (
          <div
            style={{
              right: lang == "EN" ? "30px" : "",
              left: lang == "EN" ? "" : "0px",
              position: "absolute",
              top: "-15px",
            }}
          >
            <ChartLegend />
          </div>
        )}

        <Chart
          options={options}
          type="bar"
          width={chartWidth}
          height={chartHeight}
          series={series}
        />

        {!isMobile && <ChartLegend />}
      </div>
    </div>
  );
}

export default MapApexChart;
