import React, { useState, useEffect } from "react";

import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Header from "../components/Header";
import { useMediaQuery } from "react-responsive";
import moment from "moment";
import URL from "../Settings.json";

import { useTranslation, Trans } from "react-i18next";
import NoDataToDisplay from "../components/NoDataToDisplay";
import TermDropDown from "../components/TermDropDown";
import AttendanceBtn from "./AttendanceBtn";
import axios from "axios";
const _Url = URL._Url;

function Attendance({
  ChildUserName,
  loggedInParent,
  selectedStudentId,
  lang,
  minimized,
  centerScreen,
  token,
  studentInfo,
}) {
  const [addendanceData, setaddendanceData] = useState();
  const [selectedTerm, setSelectedTerm] = useState(-1);
  const { t, i18n } = useTranslation();
  const isMobile = useMediaQuery({ query: "(max-width: 820px)" });
  const renderAttendanceData = () => {
    return (
      addendanceData &&
      addendanceData.map((att) => {
        return (
          <>
            <tr className="spaceUnder">
              <td className="tableText arabicDontChange">
                {moment(att.attendanceDate)
                  .locale(lang == "EN" ? "en" : "ar")
                  .format(" DD/MM/YYYY")}
              </td>
              <td className="tableText" style={{ textAlign: "center" }}>
                {t("Attendance.Absent")}
              </td>
              <td className="tableText" style={{ textAlign: "center" }}>
                {att.attendanceCode === "UNEXAB"
                  ? lang === "EN"
                    ? "Unexcused"
                    : "بدون عذر"
                  : lang === "EN"
                  ? "Excused"
                  : "بعذر"}
              </td>
              <td className="tableText">
                {lang == "EN"
                  ? att.reasonDescriptionAm
                  : att.reasonDescriptionPm == "Unexcused Absence"
                  ? "بدون عذر"
                  : att.reasonDescriptionPm}
              </td>
              {!isMobile && (
                <td>
                  {/* <AttendanceBtn data={att} refreshData={refreshData} /> */}
                </td>
              )}
            </tr>
            {isMobile && (
              <tr className="text-right">
                <td></td>
                <td>
                  {/* <AttendanceBtn data={att} refreshData={refreshData} /> */}
                </td>
              </tr>
            )}
          </>
        );
      })
    );
  };

  useEffect(() => {
    refreshData();
  }, [ChildUserName, selectedTerm]);

  const refreshData = () => {
    setaddendanceData([]);
    selectedStudentId &&
      axios
        .get(
          _Url +
            `/api/Attendance/GetAttendaceByStudentId/${selectedStudentId}/${
              selectedTerm ? selectedTerm : -1
            }`,
          { headers: { Authorization: token } }
        )
        .then((response) => {
          return response.data;
        })
        .then((data) => {
          setaddendanceData(data);
        })
        .catch((error) => {
          console.log(error);
        });
  };
  return (
    <>
      <Header
        studentInfo={studentInfo}
        loggedInParent={loggedInParent}
        message={t("HeaderMsg.Attendance")}
        lang={lang}
        minimized={minimized}
      />
      <div className={centerScreen}>
        <Box>
          <div elevation={3} className="AttendancePaper studentInfoBox ">
            <div className="spaceBetweenRow ColorHeader MobileOnlyColumn">
              <div className="compTitle">{t("Attendance.Attendance")}</div>
              <TermDropDown
                idd="TermSelectDD"
                setSelectedTerm={setSelectedTerm}
                URL={_Url + `/api/GetTerms`}
                lang={lang}
                token={token}
              />
            </div>

            {addendanceData && addendanceData.length != 0 && (
              <div className="AttendanceTablePadding">
                <div
                  style={{
                    position: "absolute",
                    top: isMobile ? "55px" : "87px",
                    left: "5%",
                    zIndex: "500",
                    width: "90%",
                  }}
                >
                  <hr
                    style={{
                      background: "#00ADBA ",
                      border: "1px solid #00ADBA ",
                    }}
                  />
                </div>
                <table
                  style={{ width: "100%", tableLayout: "fixed" }}
                  className="attendanceTable"
                >
                  <thead>
                    <tr
                      style={{
                        border: "10px solid grey",
                        paddingBottom: "5px",
                      }}
                    >
                      <th>{t("Attendance.Date")}</th>
                      <th>{t("Attendance.AttendanceHeader")}</th>
                      <th>{t("Attendance.Excused")}</th>
                      <th>{t("Attendance.Reason")}</th>
                      {!isMobile && <th></th>}
                    </tr>
                  </thead>
                  <tbody>{renderAttendanceData()}</tbody>
                </table>
              </div>
            )}
            <NoDataToDisplay
              noData={
                addendanceData && addendanceData.length === 0 ? true : false
              }
              lang={lang}
            />
          </div>
        </Box>
      </div>
    </>
  );
}

export default Attendance;
