import React, { useState, useEffect } from "react";

import SchoolProfile from "../SchoolProfile/SchoolProfile";

import Sidebar from "../navigation/Sidebar";
import Dashboard from "../Dashboard/Dashboard";
import Attendance from "../Attendance/Attendance";
import Resources from "../Resources/Resources";
import MediaGallary from "../MediaGallary/MediaGallary";
import FooterView from "./FooterView";

function DesktopView({
  loggedInParent,
  setLoggedInParent,
  loggedIn,
  setloggedIn,
  viewState,
  setViewState,
  selectedChild_UserName,
  setSelectedChild_UserName,
  selectedChild_Id,
  setSelectedChild_Id,
  selectedhild_Grade,
  setSelectedChild_Grade,
  studentInfo,
  setStudentInfo,
  showSideBar,
  setShowSideBar,
  lang,
  setLang,
  logOut,
  token,
}) {
  const [minimized, setMinimized] = useState(false);

  const getCenterScreen = () => {
    if (minimized) {
      return lang == "EN" ? "MinicenterScreenO" : "MiniARcenterScreenO";
    } else {
      return lang == "EN" ? "centerScreenO" : "ARcenterScreenO";
    }
  };
  const getSideBarClass = () => {
    if (showSideBar) {
      return "";
    } else return "HideClass";
  };

  useEffect(() => {}, [studentInfo]);
  return (
    <div>
      <div id="mainScreen">
        <Sidebar
          viewState={viewState}
          setViewState={setViewState}
          loggedInParent={loggedInParent}
          loggedIn={loggedIn}
          setloggedIn={setloggedIn}
          selectedChild_Id={selectedChild_Id}
          setSelectedChild_Id={setSelectedChild_Id}
          selectedChild_UserName={selectedChild_UserName}
          setSelectedChild_UserName={setSelectedChild_UserName}
          studentInfo={studentInfo}
          setStudentInfo={setStudentInfo}
          showSideBar={true}
          setShowSideBar={setShowSideBar}
          lang={lang}
          setLang={setLang}
          minimized={minimized}
          setMinimized={setMinimized}
          logOut={logOut}
          token={token}
        />

        <div className={lang == "EN" ? "ENdesktopView" : "ARdesktopView"}>
          {viewState === 0 && (
            <Dashboard
              loggedInParent={loggedInParent}
              selectedChild={selectedChild_Id}
              selectedChild_UserID={selectedChild_UserName}
              studentInfo={studentInfo}
              setStudentInfo={setStudentInfo}
              schoolCode={studentInfo && studentInfo.schoolCode}
              gradeId={selectedhild_Grade}
              classId={studentInfo && studentInfo.classId}
              lang={lang}
              minimized={minimized}
              centerScreen={getCenterScreen()}
              token={token}
            />
          )}
          {viewState === 1 && (
            <SchoolProfile
              selectedChildId={selectedChild_Id}
              loggedInParent={loggedInParent}
              studentInfo={studentInfo}
              lang={lang}
              minimized={minimized}
              centerScreen={getCenterScreen()}
              token={token}
            />
          )}
          {viewState === 2 && (
            <Attendance
              ChildUserName={selectedChild_UserName}
              loggedInParent={loggedInParent}
              studentInfo={studentInfo}
              lang={lang}
              minimized={minimized}
              centerScreen={getCenterScreen()}
              token={token}
              selectedStudentId={selectedChild_UserName.substring(1)}
            />
          )}
          {viewState === 3 && studentInfo && (
            <Resources
              schoolCode={studentInfo.schoolCode}
              gradeId={selectedhild_Grade}
              classId={studentInfo.classId}
              loggedInParent={loggedInParent}
              selectedChildId={selectedChild_UserName}
              studentInfo={studentInfo}
              lang={lang}
              minimized={minimized}
              centerScreen={getCenterScreen()}
              token={token}
            />
          )}
          {viewState === 4 && (
            <MediaGallary
              selectedChild_UserID={studentInfo && studentInfo.student_Id}
              loggedInParent={loggedInParent}
              studentInfo={studentInfo}
              lang={lang}
              minimized={minimized}
              centerScreen={getCenterScreen()}
              token={token}
            />
          )}
        </div>
      </div>
      <FooterView lang={lang} />
    </div>
  );
}

export default DesktopView;
