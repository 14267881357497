import React, { useState, useEffect } from "react";

import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { useTranslation, Trans } from "react-i18next";
import URL from "../../Settings.json";
import axios from "axios";
const _Url = URL._Url;

function AttendanceRate({ studentInfo, token }) {
  const { t, i18n } = useTranslation();
  const [attendanceRate, setAttendanceRate] = useState(0);

  const [attArray, setAttArray] = useState([100, 0]);

  useEffect(() => {
    studentInfo &&
      axios
        .get(
          _Url +
            `/api/PPMainService/v2/students/attendanceratebyesisid/${studentInfo.student_Id}`,
          { headers: { Authorization: token } }
        )
        .then((response) => {
          return response.data;
        })
        .then((data) => {
          try {
            setAttendanceRate(data[0].attendanceRate.toFixed(0));
          } catch (error) {}
        })
        .catch((error) => {
          console.log(error);
        });
  }, [studentInfo]);
  return (
    <div
      elevation={3}
      className="studentInfoBox  attendanceRateBox AbsentRateBox NavyBorder"
    >
      <div className="spaceBetweenRow mobileColumn ">
        <div className="BottomSectionTitle ">
          {" "}
          {t("StudentInfo.AttendanceRate")}
        </div>
        <div style={{ textAlign: "right" }}>
          <div className="rateText">{attendanceRate.toFixed(0)}%</div>
        </div>
      </div>
    </div>
  );
}

export default AttendanceRate;
