import React, { useState, useEffect } from "react";
import nodatapicture from "../assets/svg/nodatapicture.svg";

import nodatapictureAR from "../assets/svg/NoDataAR.png";

function NoDataToDisplay({ noData, lang }) {
  return (
    <div style={{ padding: "10px", textAlign: "right" }}>
      {noData && (
        <img
          className="NoImageToDisplay"
          src={lang == "EN" ? nodatapicture : nodatapictureAR}
        ></img>
      )}
    </div>
  );
}

export default NoDataToDisplay;
