import React, { useState, useEffect } from "react";
import emiratiMan from "../assets/emirati.PNG";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";

import femaleUser from "../assets/svg/profile/female.svg";
import maleUser from "../assets/svg/profile/male.svg";
import { useTranslation, Trans } from "react-i18next";
export default function SchoolManagment({
  Name,
  Phone,
  Email,
  SchoolRole,
  Gender,
}) {
  const { t, i18n } = useTranslation();
  return (
    <div elevation={3} className="schoolManag ">
      <div className="SchoolProfileTitle OtherBlueBackGround">{SchoolRole}</div>
      <div className="schoolManag2">
        <div className="MobileCenterImage">
          <img
            src={Gender == "Female" ? femaleUser : maleUser}
            className="SchoolImg scale08"
          />
        </div>
        <div style={{ padding: "0px", width: "100%" }}>
          <div className="p10 mobileP5">
            <div className="spaceBetween greyColor">
              <span>{t("SchoolProfile.Name")}</span>
              <span>
                {Name} {/*  */}
              </span>
            </div>
            {/* <div className="spaceBetween greyColor">
            <span>Phone Number</span>
            <span>{Phone}</span>
          </div> */}
            <div
              className="spaceBetween greyColor"
              style={{ paddingTop: "15px" }}
            >
              <span className="arabicDontChange">
                {t("SchoolProfile.Email")}
              </span>
              <span
                className="HyperLnk"
                onClick={() => (window.location = `mailto:${Email}`)}
              >
                {Email}{" "}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
