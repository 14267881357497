import React, { useState, useEffect } from "react";

import DesktopView from "./Views/DesktopView";
import MobileView from "./Views/MobileView";
import { useTranslation, Trans } from "react-i18next";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CryptoJS from "react-native-crypto-js";
import { useMediaQuery } from "react-responsive";
import "./css/App.css";
import "./css/Tablet.css";
import "./css/App125.css";
import "./css/Mobile.css";
import "./css/MobileSmall.css";
import URL from "./Settings.json";
import Login from "./Login/Login";
import "./i18n";
import TabletView from "./Views/TabletView";
import DoneAnimation from "./components/DoneAnimation";
import axios, { isCancel, AxiosError } from "axios";
const _Url = URL._Url;
const SUrl = URL.SUrl;














function App() {
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });

  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");

  const [token, setToken] = useState("");

  const [rememberMe, setRememberMe] = useState(false);

  const [lang, setLang] = useState("EN");

  const isBigScreen = useMediaQuery({ query: "(min-width: 1824px)" });
  const isTablet = useMediaQuery({ query: "(max-width: 1024px)" });
  const isMobile = useMediaQuery({ query: "(max-width: 920px)" });
  const [uservalidated, setUserValidated] = useState(false);
  const [userId, SetuserId] = useState();

  const [errorText, setErrorText] = useState("");
  const isPortrait = useMediaQuery({ query: "(orientation: portrait)" });
  const isRetina = useMediaQuery({ query: "(min-resolution: 2dppx)" });
  const [loggedInParent, setLoggedInParent] = useState({});
  const [loggedIn, setloggedIn] = useState(false);
  const [viewState, setViewState] = useState(0);

  const [selectedChild_UserName, setSelectedChild_UserName] = useState();
  const [selectedChild_Id, setSelectedChild_Id] = useState();

  const [studentInfo, setStudentInfo] = useState();

  const [isSAML, setISSAML] = useState(false)






useEffect(() => {
  const location = window.location;
  let text = location.toString();
  const PToken = text.split("?t=");
  const query_param = "Bearer "+PToken[1];
  
  
  if(query_param){
   // setISSAML(false)
     console.log(query_param);
     axios
     .get(_Url+`/auth/validatesaml/PP`,
     { headers: { Authorization: query_param } })
     .then(function (response) {
  
       setToken("Bearer " + response.data.token);
       
       if (response.data.email != "") {
         SetuserId(response.data.staffType);  //4500073010

  
         setUserValidated(true);
       } else {
         localStorage.setItem("loggedIn", false);
         setUserName("");
         setPassword("");
         toast.error("Login Failed", {
           position: "top-right",
           autoClose: 5000,
           hideProgressBar: false,
           closeOnClick: true,
           pauseOnHover: true,
           draggable: true,
           progress: undefined,
         });
         setErrorText("");
       }
     })
     .catch(function (error) {
       // handle error


       if (PToken[1]) {
        window.location.href = "https://saml.charterschools.ae/Logout";
      } else {
        logOut();
      }


       toast.error("Login Failed", {
         position: "top-right",
         autoClose: 5000,
         hideProgressBar: false,
         closeOnClick: true,
         pauseOnHover: true,
         draggable: true,
         progress: undefined,
       });
     })
     .finally(function () {
       // always executed
     });
  
  














  
  
  }
  else{
  
    console.log('no query_param');
  }
}, []);







  
  




















  const logOut = () => {
    setSelectedChild_Id(undefined);
    setStudentInfo(undefined);
    setSelectedChild_UserName(undefined);
    setUserName("");
    setPassword("");
    setStudentInfo(undefined);
    console.log("LOG OUT");
    // localStorage.setItem("loggedIn", false);
    // localStorage.setItem("rememberMe", false);
    // localStorage.setItem("user", "");
    // localStorage.setItem("password", "");
    setUserValidated(false);
    setloggedIn(false);
  };

  useEffect(() => {
    studentInfo &&
      axios
        .get(
          _Url +
            `/api/GetStudentInfo/${studentInfo.schoolCode}/${studentInfo.grade}`,
          { headers: { Authorization: token } }
        )
        .then((response) => {
          return response.data;
        })
        .then((data) => {
          if (data && data.length > 0) {
            setSelectedChild_Grade(data[0].id);
          }
        })
        .catch((error) => {
          console.log(error);
        });
  }, [studentInfo]);

  useEffect(() => {
    // const isLoggedIn = localStorage.getItem("loggedIn");
    // console.log("logged in is .... refressshing");
    // console.log(isLoggedIn);
    // isLoggedIn && setloggedIn(isLoggedIn);
    // if (isLoggedIn === "true") {
    //   console.log("in ifff");
    //   const user = rememberMe ? localStorage.getItem("user") : "";
    //   const pass = rememberMe ? localStorage.getItem("password") : "";
    //   let bytes = CryptoJS.AES.decrypt(pass, "parentPortalKey");
    //   let originalText = bytes.toString(CryptoJS.enc.Utf8);
    //   setUserName(user);
    //   setPassword(originalText);
    //   setRememberMe(rememberMe);
    //   logIn();
    // }
  }, []);

  const logIn = () => {
    axios
      .get(_Url + `/api/PPMainService/v2/validate/${userName}/${password}`)
      .then(function (response) {
        // handle success

        setToken("Bearer " + response.data.token);

        if (response.data.email != "") {
          SetuserId(userName);

          setUserValidated(true);
        } else {
          localStorage.setItem("loggedIn", false);
          setUserName("");
          setPassword("");
          toast.error("Login Failed", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setErrorText("");
        }
      })
      .catch(function (error) {
        // handle error

        toast.error("Login Failed", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      })
      .finally(function () {
        // always executed
      });

    // fetch(_Url + `/api/PPMainService/v2/validate/${userName}/${password}`)
    //   .then((response) => {
    //     console.log(response);
    //     // return response.json();
    //   })
    //   .then((data) => {
    //     console.log(data);

    //     console.log(data.token);
    //     setToken("Bearer " + data.token);

    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });
  };

  // useEffect(() => {
  //   axios
  //     .get(SUrl + `/api/academicyears`, { headers: { Authorization: token } })
  //     .then(function (response) {
  //       // handle success
  //       console.log(response);
  //     })
  //     .catch(function (error) {
  //       // handle error
  //       console.log(error);
  //     })
  //     .finally(function () {
  //       // always executed
  //     });
  // }, [token]);

  const [showSideBar, setShowSideBar] = useState(false);

  const [selectedhild_Grade, setSelectedChild_Grade] = useState();
  return (
    <>
      {/* {isMobile ? "Mobile" : "mobile false"}
      {showSideBar ? "show side bar" : "hide side bar"}
      {isDesktopOrLaptop ? "Desktop" : "not desktop"}
      {isTablet ? "tablet" : "not tablet"} */}

      <ToastContainer />
      {loggedIn && (
        <>
          {!isMobile && (
            <DesktopView
              loggedInParent={loggedInParent}
              setLoggedInParent={setLoggedInParent}
              loggedIn={loggedIn}
              setloggedIn={setloggedIn}
              viewState={viewState}
              setViewState={setViewState}
              selectedChild_UserName={selectedChild_UserName}
              setSelectedChild_UserName={setSelectedChild_UserName}
              selectedChild_Id={selectedChild_Id}
              setSelectedChild_Id={setSelectedChild_Id}
              selectedhild_Grade={selectedhild_Grade}
              setSelectedChild_Grade={setSelectedChild_Grade}
              studentInfo={studentInfo}
              setStudentInfo={setStudentInfo}
              showSideBar={showSideBar}
              setShowSideBar={setShowSideBar}
              lang={lang}
              setLang={setLang}
              logOut={logOut}
              token={token}
            />
          )}
          {isMobile && (
            <MobileView
              loggedInParent={loggedInParent}
              setLoggedInParent={setLoggedInParent}
              loggedIn={loggedIn}
              setloggedIn={setloggedIn}
              viewState={viewState}
              setViewState={setViewState}
              selectedChild_UserName={selectedChild_UserName}
              setSelectedChild_UserName={setSelectedChild_UserName}
              selectedChild_Id={selectedChild_Id}
              setSelectedChild_Id={setSelectedChild_Id}
              selectedhild_Grade={selectedhild_Grade}
              setSelectedChild_Grade={setSelectedChild_Grade}
              studentInfo={studentInfo}
              setStudentInfo={setStudentInfo}
              showSideBar={showSideBar}
              setShowSideBar={setShowSideBar}
              lang={lang}
              setLang={setLang}
              logOut={logOut}
            />
          )}
        </>
      )}
      {/* {!loggedIn && !isSAML && ( */}
      {!loggedIn  && (
        <Login
          loggedInParent={loggedInParent}
          setLoggedInParent={setLoggedInParent}
          loggedIn={loggedIn}
          setloggedIn={setloggedIn}
          lang={lang}
          setLang={setLang}
          logIn={logIn}
          setUserName={setUserName}
          password={password}
          setPassword={setPassword}
          rememberMe={rememberMe}
          setRememberMe={setRememberMe}
          uservalidated={uservalidated}
          setUserValidated={setUserValidated}
          userId={userId}
          SetuserId={SetuserId}
          errorText={errorText}
          setErrorText={setErrorText}
          token={token}
        />
      )}
    </>
  );
}

export default App;
