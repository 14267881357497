import React, { useState, useEffect } from "react";

import { useMediaQuery } from "react-responsive";

import boyUser from "../../assets/svg/maleStudent.svg";
import girlUser from "../../assets/svg/femaleStudent.svg";

import { useTranslation, Trans } from "react-i18next";
import URL from "../../Settings.json";
const _Url = URL._Url;

function StudentInfo({ studentInfo, lang, token }) {
  const { t, i18n } = useTranslation();
  const isSmallScreen = useMediaQuery({ query: "(max-width: 420px)" });
  const [genderImage, setGenderImage] = useState(boyUser);

  useEffect(() => {
    try {
      if (studentInfo.gender === "Female") {
        setGenderImage(girlUser);
        setActualPicURL(girlUser);
      } else {
        setGenderImage(boyUser);
        setActualPicURL(boyUser);
      }
    } catch {}

    try {
      setActualPicURL(
        `https://cslp.charterschools.ae/api/api/File/GetStudentImage/${studentInfo.student_Id}`
      );
    } catch (error) {
      setActualPicURL(genderImage);
    }
  }, [studentInfo]);
  const [userImage, setUserImage] = useState(genderImage);

  const [actualPicURL, setActualPicURL] = useState(genderImage);

  return (
    <>
      {" "}
      <div elevation={3} className="studentInfoBox MarginTop5 childInfoBox ">
        <div className="SectionTitle OtherBlueBackGround">
          {t("StudentInfo.ChildInfo")}
        </div>
        <div>
          <div className="displayRow smallMobileColumn">
            <div style={{ width: "280px" }}>
              <div style={{ textAlign: "center" }}>
                {" "}
                <img
                  className="userImageStudentInfo"
                  src={actualPicURL}
                  onError={(e) => (e.target.src = genderImage)}
                />
              </div>
              <div className="roww" style={{ textAlign: "center" }}>
                {studentInfo
                  ? lang == "EN"
                    ? studentInfo.en_FullName
                    : studentInfo.ar_FullName
                  : ""}{" "}
              </div>
            </div>
            <div
              className="studentInfoRow"
              style={{
                borderTop: isSmallScreen ? "1px dashed grey" : "",
                borderLeft:
                  lang == "EN" && !isSmallScreen ? "1px dashed grey" : "",
                borderRight:
                  lang == "AR" && !isSmallScreen ? "1px dashed grey" : "",
              }}
            >
              <div className="roww">
                {" "}
                <div className="LabelT">{t("StudentInfo.Age")}</div>
                <div className="LabelColin">:</div>{" "}
                <div className="arabicDontChange">
                  {studentInfo ? studentInfo.age : ""}
                </div>
              </div>
              <div className="roww">
                <div className="LabelT">{t("StudentInfo.Grade")}</div>
                <div className="LabelColin">:</div>
                <div className="arabicDontChange">
                  {studentInfo ? studentInfo.grade : ""}
                </div>
              </div>
              <div className="roww">
                <div className="LabelT">{t("StudentInfo.Section")}</div>
                <div className="LabelColin">:</div>
                <div className="arabicDontChange">
                  <div className="arabicDontChange">
                    {studentInfo ? studentInfo.section : ""}
                  </div>
                </div>
              </div>
              <div className="roww">
                <div className={lang == "EN" ? "LabelT" : "ARLabelT"}>
                  {t("StudentInfo.School")}
                </div>
                <div className="LabelColin">:</div>
                {studentInfo
                  ? lang == "EN"
                    ? studentInfo.schoolName
                    : studentInfo.schoolArabicName
                  : ""}
              </div>
              <div className="roww ">
                <div className={lang == "EN" ? "LabelT" : "ARLabelT"}>
                  {t("StudentInfo.UserName")}
                </div>

                <div className="LabelColin">:</div>
                {studentInfo ? studentInfo.username : ""}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default StudentInfo;
