import React, { useState, useEffect } from "react";

import infoIconn from "../assets/svg/resourceType/thei.svg";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";

import { useTranslation, Trans } from "react-i18next";

import femaleUser from "../assets/svg/womenprofile.svg";
import maleUser from "../assets/svg/manprofile.svg";

export default function TeacherGradeInfo({
  TeacherName,
  TeacherEmail,
  Subject,
  TeacherGender,
  lang,
}) {
  const { t, i18n } = useTranslation();

  const [showToolTip, setShowToolTip] = useState(undefined);
  const [showPopUp, setShowPopup] = useState(false);
  const [imgUrl, setImgUrl] = useState(femaleUser);

  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} open={showToolTip} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "white",
      color: "rgba(0, 0, 0, 0.87)",
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: "1px solid #dadde9",
      boxShadow: "0px 3px 21px #0e596129",
    },
  }));
  useEffect(() => {
    if (TeacherGender === "FEMALE") {
      setImgUrl(femaleUser);
    } else {
      setImgUrl(maleUser);
    }
  }, [TeacherGender]);

  const popUp = (bl) => {
    setShowPopup(bl);

    if (bl) {
      setShowToolTip(false);
    } else {
      setShowToolTip(undefined);
    }
  };
  return (
    <div onMouseLeave={() => popUp(false)}>
      <HtmlTooltip
        title={<React.Fragment>{t("AcademicRecord.ClickHere")}</React.Fragment>}
      >
        <IconButton
          place
          className="mobileInfo"
          style={{ zIndex: 500 }}
          onClick={() => popUp(true)}
        >
          <img src={infoIconn} className="infoIcon"></img>
        </IconButton>
      </HtmlTooltip>
      {showPopUp && (
        <Box>
          <Paper
            elevation={3}
            className={
              lang == "EN"
                ? "TeacherDetailPopUp EnAlign"
                : "TeacherDetailPopUp ARAlign"
            }
          >
            <div className="spaceBetweenRow ">
              <div className="p10">
                {lang == "EN"
                  ? Subject + " " + t("StudentInfo.Teacher")
                  : t("StudentInfo.Teacher") + " " + Subject}
              </div>
              <IconButton onClick={() => popUp(false)}>
                <CloseIcon />
              </IconButton>
            </div>
            <br />
            <div className="p20 MobileOnlyColumn spaceBetweenRow">
              <img src={imgUrl} className="TeacherPopUp"></img>
              {TeacherName !== "" && (
                <div style={{ marginLeft: "20px" }}>
                  <div style={{ marginBottom: "30px" }}>
                    {t("SchoolProfile.Name")}:
                    <br /> {TeacherName}
                  </div>
                  <div style={{ marginTop: "10px", marginBottom: "30px" }}>
                    {t("SchoolProfile.Email")}: <br /> {TeacherEmail}
                  </div>
                </div>
              )}
              {TeacherName == "" && (
                <div classNam="TeacherInfoUn">
                  {t("StudentInfo.TeacherInfoUnavailable")}
                </div>
              )}
            </div>
          </Paper>
        </Box>
      )}
    </div>
  );
}
