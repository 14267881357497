import React, { useState, useEffect } from "react";
import infoIconn from "../assets/svg/resourceType/thei.svg";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";

import CloseIcon from "@mui/icons-material/Close";

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "white",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,

    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
    boxShadow: "0px 3px 21px #0e596129",
  },
}));
function GradeExplanation({ Type, Explanation, lang }) {
  const [showPopUp, setShowPopup] = useState(false);

  return (
    <div onMouseLeave={() => setShowPopup(false)}>
      <HtmlTooltip title={<React.Fragment>{Type}</React.Fragment>}>
        <IconButton
          place
          style={{ zIndex: 500, padding: "0px" }}
          onClick={() => setShowPopup(true)}
        >
          <img src={infoIconn} className="infoIcon"></img>
        </IconButton>
      </HtmlTooltip>
      {showPopUp && (
        <Box>
          <Paper
            elevation={3}
            className={lang == "EN" ? "GradeExplanation " : "GradeExplanation "}
            style={{ left: "25%" }}
            onMouseLeave={() => setShowPopup(false)}
          >
            <div className="spaceBetweenRow">
              <div className="p10 ExplanationTitle"> {Type}</div>
              <IconButton onClick={() => setShowPopup(false)}>
                <CloseIcon />
              </IconButton>
            </div>
            <div className="p20">
              <div className={lang == "EN" ? "AlignLeft" : "AlignRight"}>
                {Explanation}
              </div>
            </div>
          </Paper>
        </Box>
      )}
    </div>
  );
}

export default GradeExplanation;
