import React, { useState, useEffect } from "react";
import emiratiMan from "../assets/emirati.PNG";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";

import femaleUser from "../assets/svg/profile/female.svg";
import maleUser from "../assets/svg/profile/male.svg";
import { useTranslation, Trans } from "react-i18next";
import { PregnantWomanRounded } from "@mui/icons-material";

export default function SchoolContactInfo({ ContactTitle, PhoneContact }) {
  const { t, i18n } = useTranslation();
  return (
    <div elevation={3} className="schoolManag ">
      <div className="SchoolProfileTitle OtherBlueBackGround">
        {ContactTitle}
      </div>
      <div className="schoolManag2">
        <div style={{ padding: "0px", width: "100%" }}>
          <div className="p10 mobileP5">
            <div className="spaceBetween greyColor">
              <span>{t("SchoolProfile.PhoneNumber")}</span>
              <div
                dir="ltr"
                style={{ whiteSpace: "pre-wrap" }}
                className="arabicDontChange"
              >
                {PhoneContact} {/*  */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
