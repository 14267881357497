import React, { useState, useEffect } from "react";
import lottie from "lottie-web";
import loading from "../assets/animation/Loading.json";
import { useMediaQuery } from "react-responsive";

export default function LoadingAnimation({
  width = 500,
  height = 500,
  reff = "xx",
}) {
  const isMobile = useMediaQuery({ query: "(max-width: 820px)" });
  useEffect(() => {
    lottie.loadAnimation({
      container: document.querySelector("#react-logo" + reff),
      animationData: loading,
      renderer: "svg", // "canvas", "html"
      loop: true, // boolean
      autoplay: true, // boolean
    });
  }, []);

  return (
    <div
      id={"react-logo" + reff}
      style={{
        width: isMobile ? "300" : width,
        height: isMobile ? "300" : height,
      }}
    />
  );
}
