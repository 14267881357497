import React, { useState } from "react";

function SideBarBtn({
  onBtnClick,
  BtnId,
  index,
  activeView,
  Text,
  className,
  IconClass,
  minimized,
  WhiteIcon,
  YellowIcon,
  BlueIcon,
}) {
  const [hover, setHover] = useState(false);

  const getIconSrc = () => {
    if (hover) {
      return YellowIcon;
    } else {
      if (index == activeView) {
        return BlueIcon;
      } else {
        return WhiteIcon;
      }
    }
  };

  const getTextClass = () => {
    if (minimized) {
      if (hover) {
        return "minSideBarBtn sideBarButtonHover";
      } else {
        if (index == activeView) {
          return "minSideBarBtn selectedBtn";
        } else {
          return "minSideBarBtn ";
        }
      }
    } else {
      if (hover) {
        return "sideBarButton sideBarButtonHover";
      } else {
        if (index == activeView) {
          return "sideBarButton selectedBtn";
        } else {
          return "sideBarButton ";
        }
      }
    }
  };
  return (
    <div
      className="navBarWrapper"
      id={BtnId}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <div onClick={() => onBtnClick(index)} className={getTextClass()}>
        <img src={getIconSrc()} className={IconClass} />
        {!minimized && <div className="sideBarText">{Text}</div>}
      </div>
    </div>
  );
}

export default SideBarBtn;
