import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";

import { useMediaQuery } from "react-responsive";
import axios from "axios";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 750,
  bgcolor: "background.paper",
  border: "1px solid #555555",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

export default function MediaModel({ imgSrc, imgTitle, token }) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };

  const isMobile = useMediaQuery({ query: "(max-width: 920px)" });
  const handleClose = () => {
    setOpen(false);
  };
  const download = (e) => {
    e.preventDefault();
    axios
      .get(e.target.href, {
        method: "GET",
        headers: { Authorization: token },
      })
      .then((response) => {
        response.arrayBuffer().then(function (buffer) {
          const url = window.URL.createObjectURL(new Blob([buffer]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "image.png"); //or any other extension
          document.body.appendChild(link);
          link.click();
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onImageClick = (e) => {
    if (isMobile) {
      download(e);
    } else {
      setOpen(true);
    }
    // is Mobile ? download(e) : handleOpen;
  };
  return (
    <div>
      <img
        onClick={(e) => onImageClick(e)}
        src={imgSrc}
        href={imgSrc}
        className="roundedImage mediaImage"
      ></img>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={{ ...style, width: 750, borderRadius: "8px" }}>
          <div className="spaceBetweenRow">
            <h2 id="parent-modal-title">{imgTitle}</h2>
            <div
              className="sButton"
              href={imgSrc}
              style={{ padding: "5px" }}
              download
              onClick={(e) => download(e)}
            >
              Download Image{" "}
              <i className="fa fa-download sButtonIcon" aria-hidden="true"></i>
            </div>
          </div>

          <hr style={{ margin: "10px" }} />
          <img width={750} height={400} src={imgSrc}></img>
        </Box>
      </Modal>
    </div>
  );
}
