import phone from "../assets/svg/charterIcons/Phone.svg";
import Email from "../assets/svg/charterIcons/Email.svg";
import facebook from "../assets/svg/charterIcons/Facebook.svg";

import twitter from "../assets/svg/charterIcons/twitter.svg";
import instagram from "../assets/svg/charterIcons/instagram.svg";

import { useTranslation, Trans } from "react-i18next";

function FooterView({ lang }) {
  const { t, i18n } = useTranslation();

  return (
    <div class="FooterView">
      <div className={lang == "EN" ? "bottomFooterRow" : "ARbottomFooterRow"}>
        <div class="smallFooterText">{t("FooterView.DepofEdu")}</div>
        <div className="DisplayD_FlexRow">
          {/* <a href="" target="_Blank">
            {" "}
            <img src={phone} className="FooterIcon"></img>
          </a>
          <a href="https://www.facebook.com/ADEKabudhabi/" target="_Blank">
            <img src={facebook} className="FooterIcon"></img>
          </a>
          <a href="https://twitter.com/Adek_tweet" target="_Blank">
            <img src={twitter} className="FooterIcon"></img>
          </a>
          <a
            href="https://www.instagram.com/accounts/login/?next=/Adek_Insta/"
            target="_Blank"
          >
            <img src={instagram} className="FooterIcon"></img>
          </a>
          <a>
            <img src={Email} className="FooterIcon"></img>
          </a> */}
        </div>
        <div className="DisplayD_FlexRow">
          <a
            href={
              "https://www.tamm.abudhabi/" +
              (lang == "EN" ? "en" : "ar-AE") +
              "/privacy-statement"
            }
            target="_blank"
            className="FooterLink"
          >
            {t("FooterView.PrivacyPolicy")}
          </a>
          <div className="yellowTriangle"></div>
          <a
            href={
              "https://www.tamm.abudhabi/" +
              (lang == "EN" ? "en" : "ar-AE") +
              "/terms-and-conditions"
            }
            target="_blank"
            className="FooterLink"
          >
            {t("FooterView.TermsCondition")}
          </a>
        </div>
      </div>
    </div>
  );
}

export default FooterView;
