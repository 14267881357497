import React, { useRef, useState, useEffect } from "react";

import topImage from "../assets/svg/charterIcons/topWhiteDesign.svg";
import bottomImage from "../assets/svg/charterIcons/bottomYellowDesing.svg";

import { useTranslation, Trans } from "react-i18next";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";

function Header({
  message,
  loggedInParent,
  isDashbord = false,
  studentInfo,
  lang,
  minimized,
}) {
  const getHeaderClass = () => {
    if (minimized) {
      return lang == "EN" ? "MiniHeaderBox" : "MiniHeaderBoxAR";
    } else {
      return lang == "EN" ? "HeaderBox" : "HeaderBoxAR";
    }
  };
  const { t, i18n } = useTranslation();
  return (
    <Box className={getHeaderClass()}>
      <div className="HeaderBoxPaper">
        <div>
          <div>
            <span className="welcomeBack">
              {t("welcomeBack")}
              <b>
                {" "}
                {loggedInParent &&
                  (lang == "EN"
                    ? loggedInParent.english_FirstName
                    : loggedInParent.arabic_FirstName)}{" "}
                !
              </b>
            </span>
            <br />
            <br />
            <div className="mobileWrap">
              <span className="messageText">
                <i
                  className="fa fa-user"
                  aria-hidden="true"
                  style={{ marginLeft: "5px", marginRight: "20px" }}
                ></i>
                {t("HeaderMsg.Child")}:{" "}
                {studentInfo &&
                  (lang == "EN"
                    ? studentInfo.en_FullName
                    : studentInfo.ar_FullName)}
              </span>
              {/*
              <br />
              <span className="messageText messageMobile">{message}</span>
         */}{" "}
            </div>
          </div>
          <img
            src={topImage}
            className={
              lang == "EN"
                ? "arabicSymbolImg HideMobile scale08"
                : "arabicSymbolImgAR HideMobile scale08"
            }
          ></img>
          <img
            src={bottomImage}
            className={
              lang == "EN"
                ? "bottomArabicSymbolImg HideMobile scale08"
                : "bottomArabicSymbolImgAR HideMobile scale08"
            }
          ></img>
        </div>
      </div>
    </Box>
  );
}

export default Header;
