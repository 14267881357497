import React, { useState, useEffect } from "react";

import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import boyUser from "../assets/svg/boyUser.svg";
import girlUser from "../assets/svg/girlUser.svg";
import moment from "moment";
import { useTranslation, Trans } from "react-i18next";
import { radioClasses } from "@mui/material";
import StudentInfo from "./components/StudentInfo";
import DeviceInfo from "./components/DeviceInfo";
import AttendanceBox from "./components/AttendanceBox";
import AttendanceRate from "./components/AttendanceRate";

import URL from "../Settings.json";
import axios from "axios";
const _Url = URL._Url;

function StudentInformation({
  selectedChildId,
  selectedChild_UserID,
  studentInfo,
  setStudentInfo,
  setmouseOverSection,
  lang,
  token,
}) {
  const { t, i18n } = useTranslation();
  const [admissionDate, setAdmissionDate] = useState("");
  useEffect(() => {
    studentInfo &&
      axios
        .get(
          _Url +
            `/api/PPMainService/v2/students/admissiondate/${studentInfo.student_Id}`,
          { headers: { Authorization: token } }
        )
        .then((response) => {
          return response.data;
        })
        .then((data) => {
          setAdmissionDate(data.ad);
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        })
        .finally(function () {
          // always executed
        });

    studentInfo &&
      axios
        .get(
          _Url +
            `/api/PPMainService/v2/students/admissiondate/${studentInfo.student_Id}`,
          { headers: { Authorization: token } }
        )
        .then((response) => {
          return response.data;
        })
        .then((data) => {
          setAdmissionDate(data.ad);
        })
        .catch((error) => {
          console.log("error here?");
          console.log(error);
        });
  }, [studentInfo]);
  return (
    <div
      id="StudentInformation"
      onMouseEnter={() => setmouseOverSection("StudentInformation")}
      onMouseLeave={() => setmouseOverSection("")}
    >
      <Box className="DashboardBox">
        <div className="compTitleD">{t("StudentInformation.GeneralInfo")}</div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignContent: "center",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div className="OuterInfoBox width30">
            <StudentInfo studentInfo={studentInfo} lang={lang} token={token} />
          </div>
          <div className="OuterInfoBox width30">
            <AttendanceBox
              studentInfo={studentInfo}
              lang={lang}
              token={token}
            />
          </div>
          <div className="OuterInfoBox width30">
            <DeviceInfo
              selectedChild_UserID={selectedChild_UserID}
              studentInfo={studentInfo}
              lang={lang}
              token={token}
            />
            <div
              elevation={3}
              className="studentInfoBox "
              style={{ marginTop: "30px", height: "110px" }}
            >
              <div className="BottomSectionTitle OtherBlueBackGround">
                {" "}
                {t("StudentInformation.Date")}
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <div className="DateRow">
                  {moment(admissionDate)
                    .locale(lang == "EN" ? "en" : "ar")
                    .format("DD/MM/YYYY")}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Box>
    </div>
  );
}

export default StudentInformation;
