import React, { useState, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import {
  ComposedChart,
  Line,
  Area,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

import ChartLegend from "./components/ChartLegend";
import URL from "../Settings.json";
import axios from "axios";
const _Url = URL._Url;

export default function MapChart({
  MapTitle,
  StudentId,
  MapSubjectName,
  MathCourseName,
  setShowChart,
  selectedTerm,
  token,
}) {
  const [data, setData] = useState();
  const isMobile = useMediaQuery({ query: "(max-width: 820px)" });
  const isBigScreen = useMediaQuery({ query: "(min-width: 1824px)" });
  const isTablet = useMediaQuery({ query: "(min-width: 1100px)" });
  const [chartWidth, setChartWidth] = useState(450);
  const [chartHeight, setChartHeight] = useState(350);

  useEffect(() => {
    if (isBigScreen) {
      setChartWidth(450);
      setChartHeight(350);
    } else if (isTablet) {
      setChartWidth(250);
      setChartHeight(200);
    } else {
      setChartWidth(80);
      setChartHeight(250);
    }
  }, [isBigScreen]);

  useEffect(() => {
    let x = [];

    const testUrl =
      _Url + `/api/GetMapScore/${StudentId}/${MapSubjectName}/${selectedTerm}`;

    axios
      .get(testUrl, { headers: { Authorization: token } })
      .then((response) => {
        return response.json();
      })
      .then((datax) => {
        datax.map((d) => {
          let xx = {
            name: d.termName,
            RIT: d.testRITScore,
            BenchMark: d.benchmark,
          };
          x = [...x, xx];
        });
      })
      .then(() => {
        if (x.length === 0) {
          setShowChart(false);
        }
        setData(x);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [StudentId]);

  return (
    <div id="MapSection">
      <div className="MapTitle">{MapTitle}</div>

      <div className="MapChartRow">
        {isMobile && (
          <div style={{ right: "30px", position: "absolute" }}>
            <ChartLegend />
          </div>
        )}
        <ResponsiveContainer
          width={isMobile ? "90%" : chartWidth}
          height={chartHeight}
        >
          <ComposedChart
            data={data}
            margin={{
              top: 5,
              right: 0,
              bottom: 50,
              left: 20,
            }}
          >
            <CartesianGrid stroke="#D3D3D3" horizontal />
            <defs>
              <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                <stop offset="25%" stopColor="#FCCE31" />
                <stop offset="75%" stopColor="#FA9547" />
              </linearGradient>
            </defs>
            <XAxis
              dataKey="name"
              label={{
                value: "",
                position: "insideBottomRight",
              }}
              angle={-20}
              textAnchor="end"
            />
            <YAxis
              axisLine={false}
              label={{ value: "", angle: -90, position: "insideLeft" }}
            />
            {/* <Tooltip /> */}
            <Legend verticalAlign="top" height={36} />
            {/* <Area type="monotone" dataKey="amt" fill="#FCCE31" stroke="#8884d8" /> */}
            <Bar dataKey="RIT" barSize={20} fill="url(#colorUv)" />
            <Bar type="monotone" dataKey="BenchMark" stroke="#1CF4C9" />
          </ComposedChart>
        </ResponsiveContainer>
        {!isMobile && <ChartLegend />}
      </div>
    </div>
  );
}
