import React, { useState, useEffect } from "react";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import StaticDateRangePicker from "@mui/lab/StaticDateRangePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import Box from "@mui/material/Box";

import { useTranslation, Trans } from "react-i18next";

import moment from "moment";
export default function DateRangeSelect({ setDate1, setDate2, lang }) {
  const [value, setValue] = React.useState([null, null]);

  const { t, i18n } = useTranslation();

  const [openPopUp, setOpenPopUp] = useState(false);

  useEffect(() => {
    try {
      if (value[0] == null && value[1] == null) {
      } else {
        setDate1(moment(value[0]).format("YYYY-MM-DD"));
        setDate2(moment(value[1]).format("YYYY-MM-DD"));
      }
    } catch {}
  }, [value]);

  return (
    <div style={{ position: "relative" }}>
      <Button className="sButton w150" onClick={() => setOpenPopUp(!openPopUp)}>
        <div className="spaceBetweenRow" style={{ width: "100%" }}>
          <div className="sButtonText">{t("DateRange.selectDateRange")}</div>
          <i className="fa fa-filter sButtonIcon" aria-hidden="true"></i>
        </div>
      </Button>
      <div
        className={
          openPopUp
            ? lang == "EN"
              ? "ShowDateRange"
              : "ARShowDateRange"
            : "HideDateRange"
        }
      >
        <div className="spaceBetweenRow">
          <div className="sButtonText p10">
            {t("DateRange.selectDateRange")}
          </div>
          <IconButton onClick={() => setOpenPopUp(false)}>
            <CloseIcon />
          </IconButton>
        </div>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <StaticDateRangePicker
            displayStaticWrapperAs="desktop"
            value={value}
            onChange={(newValue) => {
              setValue(newValue);
            }}
            renderInput={(startProps, endProps) => (
              <React.Fragment>
                <TextField {...startProps} />
                <Box sx={{ mx: 2 }}> to </Box>
                <TextField {...endProps} />
              </React.Fragment>
            )}
          />
        </LocalizationProvider>
      </div>
    </div>
  );
}
